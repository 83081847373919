import 'ant-design-vue/lib/style/index.less' // antd core styles
import './@kit/vendors/antd/themes/default.less' // default theme antd components
import './@kit/vendors/antd/themes/dark.less' // dark theme antd components
import './global.scss' // app & third-party component styles

import Vue from 'vue'
import VuePageTitle from 'vue-page-title'
import NProgress from 'vue-nprogress'
import VueLayers from 'vuelayers'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './localization'
import './antd'
import './registerServiceWorker'

// Importing axios instance
import api from './services/axios/index'
import api2 from './services/axios/index2'
// Importing form with validations from Ant Design
import FormModel from 'ant-design-vue'
// Importing Portal Vue
import PortalVue from 'portal-vue'
// Importing moment js
import moment from 'moment'
import 'moment/locale/es'

// Adding moment js
Vue.prototype.moment = moment
// Adding portal Vue
Vue.use(PortalVue)
// Adding form with validations from Ant Design
Vue.use(FormModel)
// Adding BootstraVue tools
Vue.use(BootstrapVue, {
  BModal: {
    headerBgVariant: 'light',
    // headerTextVariant: 'danger',
    headerCloseVariant: 'secondary',
    headerBorderVariant: 'primary',
    bodyBgVariant: 'light',
    cancelTitle: 'Cancelar',
    okTitle: 'Guardar',
  },
})

Vue.use(IconsPlugin)
Vue.use(VueLayers)

Vue.use(NProgress)
Vue.use(VuePageTitle, {
  prefix: 'EcoNet | ',
  router,
})
// Adding the Token to api
api.interceptors.request.use(request => {
  const accessToken = store.getters['user/userToken']
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})
api2.interceptors.request.use(request => {
  const accessToken = store.getters['user/userToken']
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})
// Adding the Token to api

// Our components
Vue.component('responsive-button', require('./components/responsive-button').default)
Vue.component('my-spinner', require('./components/spinner').default)
Vue.component('my-breadcrumbs', require('./components/my-breadcrumbs').default)
Vue.component('simple-modal-footer', require('./components/simpleModalFooter').default)
Vue.component('globalPlantFilter', require('./components/globalPlantFilter').default)
Vue.component('searchingBar', require('./components/searchingBar').default)
Vue.component('fadeAlert', require('./components/fadeAlert').default)
Vue.component('globalPlantSelect', require('./components/globalPlantSelect').default)
Vue.component('filesUpload', require('./components/filesUpload').default)
Vue.component('ResourceSelect', require('./components/ResourceSelect').default)
Vue.component('antGlobalPlantFilter', require('./components/antGlobalPlantFilter').default)
Vue.component('customDropdownSelect', require('./components/customDropdownSelect').default)
Vue.component('customDropdownWorkCenterSelect', require('./components/customDropdownWorkCenterSelect').default)

Vue.config.productionTip = false
const nprogress = new NProgress({ parent: 'body' })

new Vue({
  router,
  store,
  nprogress,
  i18n,
  render: h => h(App),
}).$mount('#app')
