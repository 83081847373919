import localeAntd from 'ant-design-vue/lib/locale-provider/es_ES'

const messages = {
  topBar: {
    issuesHistory: 'Historial de problemas',
    projectManagement: 'Gestión de proyecto',
    typeToSearch: 'Buscar...',
    findPages: 'Buscar páginas...',
    actions: 'Acciones',
    status: 'Estatus',
    profileMenu: {
      hello: 'Hola',
      billingPlan: 'Plan de facturación',
      role: 'Roles',
      email: 'Correo Electrónico',
      phone: 'Teléfono',
      editProfile: 'Mi perfil',
      logout: 'Cerrar sesión',
    },
  },
}

export default {
  locale: 'es_ES',
  localeAntd,
  messages,
}
