// Objeto normal(inicio) es considerado un Item
// Objeto con category: true es cosiderado category
// Objeto correspondiente a children es considerado submenu
export const getMenuData = [
  {
    title: 'Inicio',
    key: 'home',
    icon: 'house-door-fill',
    url: '/inicio',
    permissions: [],
  },
  {
    category: true,
    title: 'Manual de Usuario',
    permissions: [],
  },
  {
    title: 'Manual de Usuario',
    key: 'configs',
    icon: 'file-earmark-text-fill',
    permissions: [],
    children: [
      {
        title: 'Manual de Usuario',
        key: 'manual',
        url: '/manual',
        permissions: [],
      },
    ],
  },
  {
    category: true,
    title: 'Configuraciones',
    permissions: ['configurations', 'catalogs', 'admin_users'],
  },
  {
    title: 'Configuraciones',
    key: 'configs',
    icon: 'gear-fill',
    permissions: ['configurations', 'catalogs', 'admin_users'],
    children: [
      {
        title: 'Catálogos',
        key: 'catalogs',
        url: '/catalogos',
        permissions: ['catalogs'],
      },
      {
        title: 'Configuraciones',
        key: 'initial_settings',
        url: '/configuraciones',
        permissions: ['configurations'],
      },
      {
        title: 'Mi Perfil',
        key: 'user_profile',
        url: '/perfil-de-usuario',
        permissions: ['admin_users'],
      },
      {
        title: 'Usuarios',
        key: 'admin_users',
        url: '/EcoNet-users',
        permissions: ['admin_users'],
      },
      {
        title: 'Roles',
        key: 'admin_roles',
        url: '/EcoNet-roles',
        permissions: ['admin_users'],
      },
      // {
      //   title: 'Excel',
      //   key: 'excels',
      //   url: '/excels',
      //   permissions: [],
      // },
    ],
  },
  {
    category: true,
    title: 'Incidencias',
    permissions: [],
  },
  {
    title: 'Incidencias',
    key: 'incidents',
    icon: 'exclamation-triangle-fill',
    permissions: [],
    children: [
      // {
      //   title: 'Consultar',
      //   key: 'getincidents',
      //   url: '/get-incidents',
      //   permissions: [],
      // },
      {
        title: 'Control',
        key: 'createincidents',
        url: '/creacion_de_incidencias',
        permissions: [],
      },
      // TODO: Volver a habilitar después
      // {
      //   title: 'Mis Autorizaciones',
      //   key: 'myauthorizations',
      //   url: '/my-authorizations',
      //   permissions: [],
      // },
      // {
      //   title: 'Carga Masiva',
      //   key: 'massivecharge',
      //   url: '/massive-charge',
      //   permissions: [],
      // },
    ],
  },
  {
    category: true,
    title: 'Planeación de Servicios',
    permissions: [],
  },
  {
    title: 'Planeación de Servicios',
    key: 'services',
    icon: 'calendar3',
    permissions: [],
    children: [
      {
        title: 'General',
        key: 'service_plannings',
        url: '/planeacion-servicios',
        permissions: [],
      },
      {
        title: 'Generación del Servicio',
        key: 'generation_service_plannings',
        url: '/planeacion-servicios-generacion',
        permissions: [],
      },
    ],
  },
  {
    category: true,
    title: 'Vigilancia',
    permissions: [],
  },
  {
    title: 'Vigilancia',
    key: 'security',
    icon: 'lock-fill',
    permissions: [],
    children: [
      {
        title: 'Tabla Vigilancia',
        key: 'security_table',
        url: '/vigilancia',
        permissions: [],
      },
    ],
  },
  {
    category: true,
    title: 'Operaciones',
    permissions: [],
  },
  {
    title: 'Operaciones',
    key: 'operations',
    icon: 'truck-flatbed',
    permissions: [],
    children: [
      {
        title: 'Principal',
        key: 'operations',
        url: '/operaciones',
        permissions: [],
      },
      {
        title: 'Almacenes',
        key: 'warehouses',
        url: '/operaciones/almacenes',
        permissions: [],
      },
      {
        title: 'Proceso Operativo',
        key: 'operative_process',
        url: '/',
        permissions: [],
      },
      {
        title: 'Control Operacional',
        key: 'operational_control',
        url: '/operaciones/control_operaciones',
        permissions: [],
      },
    ],
  },
  {
    category: true,
    title: 'Laboratorio',
    permissions: [],
  },
  {
    title: 'Laboratorio',
    key: 'laboratory',
    icon: 'calculator-fill',
    permissions: [],
    children: [
      {
        title: 'Principal',
        key: 'laboratories',
        url: '/laboratorio',
        permissions: [],
      },
      {
        title: 'Inspección Visual',
        key: 'visual_inspection',
        url: '/laboratorio/inspeccion_visual',
        permissions: [],
      },
      {
        title: 'Recepción de muestras',
        key: 'sample_receipt',
        url: '/laboratorio/recepcion_muestras',
        permissions: [],
      },
      {
        title: 'Master de reactivos',
        key: 'reagent_master',
        url: '/laboratorio/master_reactivos',
        permissions: [],
      },
      {
        title: 'Almacén de reactivos',
        key: 'reagent_warehouse',
        url: '/laboratorio/almacen_reactivos',
        permissions: [],
      },
      {
        title: 'Almacén de muestras',
        key: 'warehouse_sample',
        url: '/laboratorio/almacen_muestras',
        permissions: [],
      },
      {
        title: 'Análisis de Laboratorios',
        key: 'lab_analisis',
        url: '/',
        permissions: [],
      },
      {
        title: 'Equipos',
        key: 'lab_equipment',
        url: '/laboratorio/equipos_laboratorio',
        permissions: [],
      },
    ],
  },
  {
    category: true,
    title: 'Administración',
    permissions: [],
  },
  {
    title: 'Administración',
    key: 'administration',
    icon: 'folder-fill',
    permissions: [],
    children: [
      {
        title: 'Principal',
        key: 'administration',
        url: '/administracion',
        permissions: [],
      },
    ],
  },
  {
    category: true,
    title: 'Resultados',
    permissions: [],
  },
  {
    title: 'Resultados',
    key: 'results',
    icon: 'graph-up',
    permissions: [],
    children: [
      {
        title: 'Principal',
        key: 'reports',
        url: '/reportes',
        permissions: [],
      },
      {
        title: 'Planeación de Servicios',
        key: 'service_plannings_reports',
        url: '/suboper',
        permissions: [],
      },
      {
        title: 'Logística',
        key: 'logistic_reports',
        url: '/reportes/reportes_logistica',
        permissions: [],
      },
      {
        title: 'Laboratorios',
        key: 'laboratories_reports',
        url: '/suboper',
        permissions: [],
      },
      {
        title: 'Operaciones',
        key: 'operations_reports',
        url: '/suboper',
        permissions: [],
      },
      {
        title: 'Administración',
        key: 'administration_reports',
        url: '/suboper',
        permissions: [],
      },
    ],
  },
]
