<template>
  <div>
    <a-form-item v-if="!refAndPop" :label="label">
      <a-select v-model="modelValue" :placeholder="placeholder" style="width: 100%"
                @search="handleSearch"
                @change="handleChange"
                :filter-option="false"
                :not-found-content="loading ? undefined : null"
                :disabled="CanUserSelectAnyPlant()"
                :loading="loading" allowClear showSearch>
        <div slot="dropdownRender" slot-scope="menu">
          <v-nodes :vnodes="menu" />
          <a-divider style="margin: 4px 0;" />
          <a-row align="middle" :gutter="[8,8]">
            <a-col :span="8" :offset="4">
              <a-button style="cursor: pointer;"
                v-if="page > 1"
                @mousedown="e => e.preventDefault()" @click="handleLess">
                <a-icon type="left" />
              </a-button>
            </a-col>
            <a-col :span="8" :offset="4">
              <a-button style="cursor: pointer;"
                v-if="options.length === pageSize"
                @mousedown="e => e.preventDefault()" @click="handleMore">
                <a-icon type="right" />
              </a-button>
            </a-col>
          </a-row>
          <a-divider style="margin: 4px 0;" />
          <span class="mx-4">
            Se muestran
            <strong class="text-primary">{{options.length}}</strong>
            de
            <strong>{{total}}</strong>
            </span>
        </div>
        <a-select-option  v-for="option in options" :key="option.id" :value="option[optionValue]" >
          <slot :option="option">
            {{option.name}}
          </slot>
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-model-item v-else :label="label" :ref="refAndPop" :prop="refAndPop">
      <a-select v-model="modelValue" :placeholder="placeholder" style="width: 100%"
                @search="handleSearch"
                @change="handleChange"
                :filter-option="false"
                :not-found-content="loading ? undefined : null"
                :disabled="CanUserSelectAnyPlant()"
                :loading="loading" allowClear showSearch>
        <div slot="dropdownRender" slot-scope="menu">
          <v-nodes :vnodes="menu" />
          <a-divider style="margin: 4px 0;" />
          <a-row align="middle" :gutter="[8,8]">
            <a-col :span="8" :offset="4">
              <a-button style="cursor: pointer;"
                v-if="page > 1"
                @mousedown="e => e.preventDefault()" @click="handleLess">
                <a-icon type="left" />
              </a-button>
            </a-col>
            <a-col :span="8" :offset="4">
              <a-button style="cursor: pointer;"
                v-if="options.length === pageSize"
                @mousedown="e => e.preventDefault()" @click="handleMore">
                <a-icon type="right" />
              </a-button>
            </a-col>
          </a-row>
          <a-divider style="margin: 4px 0;" />
          <span class="mx-4">
            Se muestran
            <strong class="text-primary">{{options.length}}</strong>
            de
            <strong>{{total}}</strong>
            </span>
        </div>
        <a-select-option  v-for="option in options" :key="option.id" :value="option[optionValue]" >
          <slot :option="option">
            {{option.name}}
          </slot>
        </a-select-option>
      </a-select>
    </a-form-model-item>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
import { ApiV2Provider } from '@/services/providers/ApiV2Provider.js'
const apiV2Provider = new ApiV2Provider()
export default {
  name: 'customDropdownWorkCenterSelect',
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  props: {
    // ----- Required -------
    // @desc: This prop is to handle v-model value
    value: {
      default: undefined,
      required: true,
    },
    // ----- Required -------
    // ----- Not required -------
    // @desc: This prop is to set the custom params of the resource, it is also used for set the fetch url params
    filters: {
      type: Object,
      required: false,
      default: function() { return {} },
    },
    // -- Standard constant params
    // -- Standard constant params
    // @desc: This prop is to set the key from the data object to be the value on the v-model, id by default
    optionValue: {
      default: 'id',
      type: String,
    },
    // ----- Behavoir props -----
    // @desc: This prop is the HTML tag of the form-item and to set the ref and pop attributes in case of a-form-model-item
    refAndPop: {
      default: '',
      type: String,
      required: false,
    },
    // ----- Not required -------
  },
  watch: {
    // @desc: This watcher is to handle the dynamic custom params of the resource, so the component can modify the url when the filters object changes and then fetch
    filters: 'customFiltersChange',
  },
  computed: {
    ...mapGetters('user', ['userPlantId', 'userPermissions', 'userRoles']),
    // @desc: Two-way data binding for v-model
    modelValue: {
      // getter
      get () {
        if (this.value === null) {
          return undefined
        } else return this.value
      },
      // setter
      set (newValue) {
        if (newValue === null) {
          this.$emit('input', undefined)
        } else this.$emit('input', newValue)
      },
    },
  },
  data() {
    this.handleSearch = debounce(this.handleSearch, 800)
    return {
      // @desc: variables to handle the api response
      loading: false,
      options: [],
      total: 0,
      // @desc: variables to modify the api request
      page: 1,
      search: '',
      params: {},
      // @desc: provider who handle the api
      apiV2Provider,
      // workCenters Logic
      resource: 'workCenters',
      label: 'Planta',
      placeholder: 'Seleccionar Planta',
      pageSize: 100,
      orderField: 'name',
      orderType: 'asc',
    }
  },
  methods: {
    // @desc: handle the search event with a debounce of 800 ms
    // @value: Event | {string} from the input of the select box (modifies the api request)
    async handleSearch(value) {
      this.search = value
      this.page = 1
      await this.Fetch()
    },
    // @desc: handle change when the value is undefined
    async handleDeselect() {
      this.search = ''
      this.page = 1
      await this.Fetch()
    },
    // @desc: handle the filters prop change, reset the page and fetch
    async customFiltersChange() {
      this.page = 1
      await this.Fetch()
    },
    // @desc: handle the click of handleMore button (modifies the api request)
    async handleMore() {
      if (this.options.length === this.pageSize) {
        this.page += 1
        await this.Fetch()
      }
    },
    // @desc: handle the click of handleLess button (modifies the api request)
    async handleLess() {
      if (this.page > 1) {
        this.page -= 1
        await this.Fetch()
      }
    },
    // @desc: (Custom event) handle the change event of the select and transfers the value information to parent component
    // @value: Event
    async handleChange(value) {
      this.$emit('change', value)
      if (value === undefined) {
        await this.handleDeselect()
      }
    },
    // @desc: create the request url from the resource, page, pageSize, search, orderField, orderType and filters
    SetRequest () {
      this.params = {}
      const params = {
        page: this.page,
        pageSize: this.pageSize,
      }

      if (this.orderField) {
        params.orderField = this.orderField
        params.orderType = this.orderType
      }
      if (this.search) params.search = this.search

      if (Object.keys(this.filters).length > 0) {
        Object.entries(this.filters).forEach(([key, value]) => {
          params[key] = value
        })
      }
      this.params = params
    },
    // @desc: recalculate the url and params by SetRequest, then fetch the request using the Get method in apiV2Provider and the url
    async Fetch() {
      this.loading = true
      this.SetRequest()
      const response = await this.apiV2Provider.Get(`fetch/${this.resource}`, this.params)
      if (response) {
        this.total = response.total
        this.options = [...response.data]
      }
      this.loading = false
    },
    // @desc: handle if user is admin logic
    CanUserSelectAnyPlant() {
      // Si retorna TRUE el filtro se bloquea
      // Si retorna FALSE el filtro será editable
      return !this.userRoles.includes('admin')
    },
  },
  async mounted() {
    await this.Fetch()
    if (this.userPlantId) {
      this.$emit('input', this.userPlantId)
    }
  },
}
</script>
