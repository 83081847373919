
// -----  HELPERS TO TRANSFORM JsonApi responses and resource -----//

// Transform a object of objects to an array of objects
export const objectArray = (response) => {
  const data = response
  delete data.jsonApi
  const array = Object.keys(data).map(key => {
    if (key !== 'jsonApi') {
      // return Object.assign(data[key], { _showDetails: false })
      return data[key]
    }
  })
  return array
}

// Set format for pagination objects
export const resolvePagination = (paginationResponse) => {
  return {
    current: paginationResponse['current-page'],
    pageSize: paginationResponse['per-page'],
    lastPage: paginationResponse['last-page'],
    from: paginationResponse.from,
    to: paginationResponse.to,
    total: paginationResponse.total,
  }
}
