<template>
  <b-alert dismissible fade :variant="variant"
           :show="dismissCountDown" @dismiss-count-down="countDownChanged">
    {{ text }}
    <b-progress
      :variant="variant"
      :max="dismissSecs"
      :value="dismissCountDown"
      height="4px"
    ></b-progress>
  </b-alert>
</template>

<script>
export default {
  name: 'fadeAlert',
  props: {
    text: {
      type: [String],
      required: true,
    },
    value: {
      default: false,
      type: [Boolean],
      required: false,
    },
    variant: {
      type: [String],
      required: true,
    },
  },
  data() {
    return {
      dismissSecs: 3,
      dismissCountDown: 0,
    }
  },
  // computed: {
  //   showAux: {
  //     get() {
  //       return this.show
  //     },
  //     set(value) {
  //       this.$emit('listchange', value)
  //     },
  //   },
  // },
  watch: {
    dismissCountDown(newValue) {
      if (newValue === 0) {
        this.$emit('input', false)
      }
    },
    value(newValue, oldValue) {
      if (newValue === true && oldValue === false) {
        this.dismissCountDown = this.dismissSecs
      }
    },
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
  },
}
</script>

<style scoped>

</style>
