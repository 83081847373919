import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

import * as sanctum from '@/services/sanctum'
import api from '@/services/axios/index'

const mapAuthProviders = {
  sanctum: {
    login: sanctum.login,
    register: sanctum.register,
    currentAccount: sanctum.currentAccount,
    logout: sanctum.logout,
    loginSocialite: sanctum.loginSocialite,
    loginSocialiteCallback: sanctum.loginSocialiteCallback,
  },
}

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    name: '',
    email: '',
    avatar: '',
    authorized: false, // false is default value
    loading: false,
    accessToken: null,
    roles: [],
    permissions: [],
    allPermissionsNames: [],
    allRolesNames: [],
    employeeSettings: null,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    LOGIN({ commit, dispatch }, { payload }) {
      const { email, password } = payload
      commit('SET_STATE', {
        loading: true,
      })
      const login = mapAuthProviders.sanctum.login

      login(email, password).then(success => {
        if (success) {
          commit('SET_STATE', {
            accessToken: success.token,
          })
          dispatch('LOAD_CURRENT_ACCOUNT')
        }
        if (!success) {
          commit('SET_STATE', {
            loading: false,
          })
        }
      })
        .catch(() => {
          commit('SET_STATE', {
            loading: false,
          })
        })
    },
    SOCIALITE_LOGIN({ commit }, { payload }) {
      commit('SET_STATE', {
        loading: false,
      })
      const login = mapAuthProviders.sanctum.loginSocialite

      login(payload).then(success => {
        if (success) {
          window.location.href = success.url
        }
        commit('SET_STATE', {
          loading: false,
        })
      })
    },
    SOCIALITE_LOGIN_CALLBACK({ commit, dispatch }, { payload }) {
      commit('SET_STATE', {
        loading: false,
      })
      const { provider, code } = payload
      const callback = mapAuthProviders.sanctum.loginSocialiteCallback
      callback(provider, code).then(success => {
        if (success) {
          commit('SET_STATE', {
            accessToken: success.token,
          })
          dispatch('LOAD_CURRENT_ACCOUNT')
        }
        commit('SET_STATE', {
          loading: false,
        })
      })
    },
    REGISTER({ commit, dispatch, rootState }, { payload }) {
      const { email, password, name, passwordConfirmation } = payload
      commit('SET_STATE', {
        loading: true,
      })

      const register = mapAuthProviders.sanctum.register
      register(email, password, name, passwordConfirmation).then(success => {
        if (success) {
          commit('SET_STATE', {
            accessToken: success.token,
          })
          dispatch('LOAD_CURRENT_ACCOUNT')
        }
        if (!success) {
          commit('SET_STATE', {
            loading: false,
          })
        }
      })
    },
    LOAD_CURRENT_ACCOUNT({ commit }) {
      commit('SET_STATE', {
        loading: true,
      })

      const currentAccount = mapAuthProviders.sanctum.currentAccount
      currentAccount().then(response => {
        if (response) {
          const { user } = response
          commit('SET_STATE', {
            name: user.name,
            email: user.email,
            roles: user.allRoles,
            permissions: user.allPermissions,
            allPermissionsNames: user.allPermissionsNames,
            allRolesNames: user.allRolesNames,
            avatar: user.profile_photo_url,
            authorized: true,
            employeeSettings: user.employeeSettings,
          })
        }
        Vue.prototype.$notification.success({
          message: 'Bienvenido',
          description: '¡Ha ingresado a la Plataforma EcoNet!',
        })
        commit('SET_STATE', {
          loading: false,
        })
        router.push({ name: 'home' })
        // window.location.replace(process.env.VUE_APP_FRONTEND_URL)
      })
    },
    LOGOUT({ commit }) {
      const logout = mapAuthProviders.sanctum.logout
      logout().then(() => {
        commit('SET_STATE', {
          name: '',
          email: '',
          avatar: '',
          authorized: false,
          loading: false,
          accessToken: null,
          employeeSettings: null,
        })
        router.push('/auth/login')
      })
    },
    UPDATE_PROFILE({ commit, dispatch, rootState }, { payload }) {
      const { email, name } = payload
      commit('SET_STATE', {
        loading: true,
      })
      api.put('/user/profile-information', {
        name,
        email,
      }).then(response => {
        if (response) {
          dispatch('GET_PROFILE')
        }
      })
        .catch(err => {
          console.log(err)
          commit('SET_STATE', {
            loading: false,
          })
        })

      commit('SET_STATE', {
        loading: false,
      })
    },
    GET_PROFILE({ commit }) {
      const currentAccount = mapAuthProviders.sanctum.currentAccount

      currentAccount().then(response => {
        if (response) {
          const { user } = response
          commit('SET_STATE', {
            name: user.name,
            roles: user.allRoles,
            permissions: user.allPermissions,
            allPermissionsNames: user.allPermissionsNames,
            allRolesNames: user.allRolesNames,
            avatar: user.profile_photo_url,
          })
        }
      })
    },
  },
  getters: {
    user: state => state,
    userToken: state => (state.accessToken === null) ? '' : state.accessToken,
    userPlantId: state => state.employeeSettings?.work_centers.code,
    userPermissions: state => state.allPermissionsNames,
    userRoles: state => state.allRolesNames,
  },
}
