import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    selectedWC: undefined,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    SET_WORK_CENTER: (state, newItem) => {
      state.selectedWC = newItem
    },

  },
  actions: {
    // Salidas - Traspasos
    SAVE_WORK_CENTER({ commit }, newItem) {
      commit('SET_WORK_CENTER', newItem)
    },
    RESET_WORK_CENTER({ commit }) {
      commit('SET_WORK_CENTER', undefined)
    },
    CLEAN_STATE({ commit }) {
      commit('SET_STATE', {
        selectedWC: undefined,
      })
    },
  },
  getters: {},
}
