<template>
  <b-container fluid class="mt-4">
    <!-- Barra de Control -->
    <div class="row my-4 justify-content-end">
      <!-- *******SEARCH********** -->
      <!--      Solo el filtro-->
      <div :class="searchSize" v-if="components.includes('filter')">
        <!--    Filter  -->
        <b-form-group class="mb-0">
          <div slot="description" class="text-left" v-if="description">
            <slot name="searchDescription"/>
          </div>
          <b-input-group>
            <b-form-input  v-model="filterValue" type="search"
              id="filterInput" :placeholder="placeholder"
              @keyup.enter ="FilterData()" @keyup.esc ="clear()"/>
            <b-input-group-append>
              <b-button variant="default" size="sm"
                        :disabled="!filterValue" @click="clear()">
                <b-icon-x/>
              </b-button>
              <b-button variant="primary" size="sm" @click="FilterData()" >
                <b-icon-search/>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <!--    Filter  -->
      </div>
      <!-- *******SEARCH********** -->
      <!-- *******SORT & PAGES********** -->
      <!--     Filtro,Sort & Pages -->
      <div class="col-12 col-md-4" v-if="components.includes('filter') && (components.includes('sort') || components.includes('pages'))">
        <!--    Sort & Paginate  -->
        <b-form-group>
          <b-input-group>
            <template v-if="components.includes('sort')">
              <b-form-select v-model="sortBy" :options="sortByOptions" @change="FilterData()" class="w-50" size="sm" />
              <b-form-select v-model="sortDirection" :options="sortOptions" @change="FilterData()" class="w-25" size="sm" :disabled="!sortBy"/>
            </template>
            <b-form-select v-if="components.includes('pages')" v-model="perPage"  :options="pageOptions" @change="FilterData()" class="w-25" size="sm"/>
          </b-input-group>
        </b-form-group>
        <!--    Sort & Paginate  -->
      </div>
      <!--     Sort & Pages -->
      <div class="col-12 col-md-4" v-else-if="components.includes('sort') || components.includes('pages')">
        <!--    Sort & Paginate  -->
        <b-form-group>
          <b-input-group>
            <template v-if="components.includes('sort')">
              <b-form-select v-model="sortBy" :options="sortByOptions" @change="FilterData()" class="w-50" size="sm" />
              <b-form-select v-model="sortDirection" :options="sortOptions" @change="FilterData()" class="w-25" size="sm" :disabled="!sortBy"/>
            </template>
            <b-form-select v-if="components.includes('pages')" v-model="perPage"  :options="pageOptions" @change="FilterData()" class="w-25" size="sm"/>
          </b-input-group>
        </b-form-group>
        <!--    Sort & Paginate  -->
      </div>
      <!-- *******SORT & PAGES********** -->
      <!-- boton para Sort & Pages -->
      <div  class="col-12 offset-0 col-md-4 offset-md-1 col-lg-2 offset-lg-0" v-if="!components.includes('filter')">
        <b-button variant="primary" size="sm" @click="FilterData()" block>
          <b-icon-search/>
        </b-button>
      </div>
    </div>
    <slot name="customFilters"/>
  </b-container>
</template>

<script>
export default {
  name: 'searchingBar',
  props: {
    components: {
      type: Array,
      required: false,
      default: () => ['filter', 'sort', 'pages'],
    },
    sortByOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Buscar...',
    },
    description: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filterValue: '',
      sortOptions: [
        { value: '', text: 'Orden', disabled: true },
        { value: '', text: 'Asc' },
        { value: '-', text: 'Desc' },
      ],
      sortBy: '',
      sortDirection: '',
      currentPage: 1,
      perPage: 15,
      pageOptions: [
        { value: 15, text: '#', disabled: true },
        { value: 10, text: '10' },
        { value: 15, text: '15' },
        { value: 50, text: '50' },
      ],
    }
  },
  computed: {
    searchSize () {
      if (this.components.includes('filter') && (this.components.includes('sort') || this.components.includes('pages'))) {
        return 'col-12 col-md-8'
      } else if (this.components.includes('filter') && (!this.components.includes('sort') && !this.components.includes('pages'))) {
        return 'col-12 col-md-12'
      } else return 'col-12'
    },
  },
  watch: {
    filterValue(newValue) {
      this.$emit('filterValue', newValue)
      this.$emit('currentPage', 1)
    },
    perPage(newValue) {
      this.$emit('pageSize', newValue)
    },
    currentPage(newValue) {
      this.$emit('currentPage', newValue)
    },
    sortBy(newValue) {
      const sortValue = `${this.sortDirection}${newValue}`
      this.$emit('sortValue', sortValue)
    },
    sortDirection(newValue) {
      const sortValue = `${newValue}${this.sortBy}`
      this.$emit('sortValue', sortValue)
    },
  },
  methods: {
    FilterData() {
      this.$emit('Fectch')
    },
    clear() {
      this.filterValue = ''
      this.currentPage = 1
      this.$emit('currentPage', 1)
      this.$emit('filterValue', '')
      this.$emit('Fectch')
    },
  },
}
</script>

<style scoped>

</style>
