import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    serviceID: '',
    // Vigilancia
    // --Check correspondiente a ruta con name: 'reception_service_plannings' vigilancia
    surveillanceCheck: process.env.VUE_APP_CHECK_VIGILANCIA,
    // Vigilancia
    // Transporte
    // --Check correspondiente a ruta con name: 'reception_service_plannings' laboratorio
    laboratoryCheck: process.env.VUE_APP_CHECK_LABORATORIO,
    // --Check correspondiente a ruta con name: 'discharge_service_plannings' Inspeccion Descarga Check
    dischargeCheck: process.env.VUE_APP_CHECK_DESCARGA,
    gondolaCheck: process.env.VUE_APP_CHECK_GONDOLAS,
    // Transporte
    // General
    // -- Objeto que contiene las estructuras de los checks
    CheckStructure: {},
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    ADD_CHECKSTRUCTURE_KEY(state, payload) {
      const { check, self, data } = payload
      state.CheckStructure[check] = {
        self,
        checkLists: data,
      }
    },
    DELETE_CHECKSTRUCTURE_KEY(state, payload) {
      const { check } = payload
      delete state.CheckStructure[check]
    },
  },
  actions: {
    // General
    SET_SERVICEID({ commit }, payload) {
      commit('SET_STATE', {
        serviceID: payload,
      })
    },
    SET_CHECKSTRUCTURE({ commit }, payload) {
      // delete payload.jsonApi
      commit('ADD_CHECKSTRUCTURE_KEY', payload)
    },
    RESET_CHECKSTRUCTURE({ commit }) {
      commit('SET_STATE', {
        CheckStructure: {},
      })
    },
    REMOVE_CHECKSTRUCTURE_KEY({ commit }, payload) {
      commit('DELETE_CHECKSTRUCTURE_KEY', payload)
    },
    // Vigilancia
    // Transporte
  },
  getters: {
    receptionState: state => state,
    getServiceID(state) {
      return state.serviceID
    },
    // Vigilancia
    getCheckStructure: state => check => {
      if (Object.prototype.hasOwnProperty.call(state.CheckStructure, check)) {
        if (Object.prototype.hasOwnProperty.call(state.CheckStructure[check], 'checkLists')) {
          return state.CheckStructure[check]
        } else {
          return {}
        }
      } else {
        return {}
      }
    },

    getSurveillanceCheckListAnswer(state) {
      return state.surveillanceCheckListAnswer
    },
    // Vigilancia
    // Transporte
  },
}
