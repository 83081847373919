import { Modal, notification } from 'ant-design-vue'
import store from '@/store'

export const errorIterator = (error) => {
  const status = error.response.status
  const { data } = error.response
  console.log(error, status, data)
  if (data) {
    const errors = data.errors
    if (error && typeof errors === 'string') {
      // Es un string desde el ApiResponser
      Modal.error({
        title: 'Ha ocurrido un error',
        content: errors,
        zIndex: 3000,
      })
    } else if (error && typeof errors === 'object') {
      // Es un error manejado por Laravel
      if (status === 422) {
        validationError(errors)
      } else multipleErrors(data.errors)
    } else defaultError()
  } else defaultError()
}

const multipleErrors = (errors) => {
  errors.forEach(error => {
    const { status } = error
    notification.warning({
      message: errorDictionary[status],
    })
  })
}

export const validationError = (errors) => {
  // Existen 2 casos para errores manejados por Laravel
  // 1 errors es un arreglo de objetos donde cada objeto con una estructura con llaves (status,title,detail)
  // 2 erros es un objeto de N llaves donde N corresponde a cada campo y es un arreglo con x cantidad de strings
  // Verificamos entonces la estructura de errors
  if (Array.isArray(errors)) {
    // corresponde a 1, solo activamos el modal
    store.commit('SHOW_ERRORS_MODAL', errors)
  } else if (typeof errors === 'object') {
    // corresponde a 2
    // debe procesarse antes de activar el modal
    const auxErrors = []
    Object.entries(errors).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(errorMsg => {
          auxErrors.push({
            status: 422,
            title: 'Error de Validación',
            detail: errorMsg,
          })
        })
      } else if (typeof value === 'string') {
        auxErrors.push({
          status: 422,
          title: 'Error de Validación',
          detail: value,
        })
      } else {
        auxErrors.push({
          status: 422,
          title: 'Error de Validación',
          detail: 'Ha ocurrido un error en el envio de datos',
        })
      }
    })
    if (auxErrors.length > 0) store.commit('SHOW_ERRORS_MODAL', auxErrors)
  }
}

const defaultError = () => {
  Modal.error({
    title: 'Ha ocurrido un error',
    content: 'Ha ocurrido un error al conectarse con el servidor, intente nuevamente.',
    zIndex: 3000,
  })
}

const errorDictionary = {
  400: 'Esta respuesta significa que el servidor no pudo interpretar la solicitud dada una sintaxis inválida.',
  401: 'Es necesario iniciar sesión para obtener la respuesta solicitada.',
  402: 'Error en la conexión, revise Payment Required',
  403: 'El usuario no posee los permisos necesarios.',
  404: 'El servidor no pudo encontrar el contenido solicitado.',
  405: 'El método solicitado es conocido por el servidor pero ha sido deshabilitado y no puede ser utilizado.',
  406: 'No se ha encontrado ningún contenido satisfactorio a la petición',
  407: 'Es necesario iniciar sesión a partir de un proxy.',
  408: 'Conexión inactiva del servidor.',
  409: 'Existe un conflicto con el estado actual del servidor.',
  410: 'El contenido solicitado ha sido borrado del servidor.',
  411: 'El servidor rechaza la petición porque el campo de encabezado Content-Length no esta definido y el servidor lo requiere.',
  412: 'El cliente ha indicado pre-condiciones en sus encabezados la cual el servidor no cumple.',
  413: 'La entidad de petición es más larga que los límites definidos por el servidor.',
  414: 'La URI solicitada por el cliente es más larga de lo que el servidor está dispuesto a interpretar.',
  415: 'El formato multimedia de los datos solicitados no está soportado por el servidor.',
  416: 'El rango especificado por el campo de encabezado Range en la solicitud es inválido.',
  417: 'La expectativa indicada por el campo de encabezado Expect solicitada no puede ser cumplida por el servidor.',
  418: 'El servidor se rehúsa a intentar hacer café con una tetera.',
  421: 'La petición fue dirigida a un servidor que no es capaz de producir una respuesta. ',
  422: 'Error de Validación',
  423: 'El recurso que está siendo accedido está bloqueado.',
  424: 'La petición falló debido a una falla de una petición previa.',
  426: 'Imposible realizar la petición, revise el protocolo.',
  428: 'El servidor origen requiere que la solicitud sea condicional.',
  429: 'El usuario ha enviado demasiadas solicitudes.',
  431: 'Los campos de encabezado de la petición son demasiado largos.',
  451: 'El usuario solicita un recurso ilegal.',
  500: 'Error de Servidor: Imposible resolver la petición.',
  501: 'Error de Servidor: El método solicitado no es soportado.',
  502: 'Error de Servidor: Se ha obtenido una respuesta inválida.',
  503: 'Error de Servidor: El servidor no está listo para manejar la petición. ',
  504: 'Error de Servidor:  Imposible obtener una respuesta debidoa intentar actuar como una puerta de enlace.',
  505: 'Error de Servidor: La versión de HTTP usada en la petición no está soportada por el servidor.',
  506: 'Error de Servidor: Negociación de contenido transparente para la petición resulta en una referencia circular.',
  507: 'Error de Servidor:  La variable de recurso escogida está configurada para acoplar la negociación de contenido transparente misma, y no es por lo tanto un punto final adecuado para el proceso de negociación',
  508: 'Error de Servidor: El servidor detectó un ciclo infinito mientras procesaba la solicitud',
  510: 'Error de Servidor: Extensiones adicionales para la solicitud son requeridas.',
  511: 'Error de Servidor: Es necesario inciar sesión para obtener acceso a la red',
}
