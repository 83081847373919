<template>
  <a-select v-model="modelValue" :disabled="CanUserSelectAnyPlant()"
            placeholder="Seleccionar Planta" style="width: 100%"
            @change="onChange($event)"
            :filter-option="filterOption"
            :loading="loading" allowClear showSearch>
    <a-select-option  v-for="option in options" :key="option.id" :value="option.id" >
      {{option.name}}
    </a-select-option>
  </a-select>
</template>

<script>
import { mapGetters } from 'vuex'
import { BaseProvider } from '@/services/providers/BaseProvider'
const baseProvider = new BaseProvider()
export default {
  name: 'antGlobalPlantFilter',
  data() {
    return {
      baseProvider,
      options: [],
      loading: true,
    }
  },
  props: {
    value: {
      default: undefined,
      // type: [String, Number, undefined],
      required: true,
    },
  },
  computed: {
    ...mapGetters('user', ['userPlantId', 'userPermissions', 'userRoles']),
    modelValue: {
      // getter
      get () {
        return this.value
      },
      // setter
      set (newValue) {
        this.$emit('input', newValue)
      },
    },
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    onChange(e) {
      this.$emit('change', e)
    },
    async Fetch() {
      this.options = await this.baseProvider.getCombo('/workCenters', { sort: 'name' })
      this.loading = false
    },
    CanUserSelectAnyPlant() {
      // Si retorna TRUE el filtro se bloquea
      // Si retorna FALSE el filtro será editable
      return !this.userRoles.includes('admin')
    },
  },
  async mounted() {
    await this.Fetch()
    if (this.userPlantId) {
      this.$emit('input', this.userPlantId)
    }
    // else {
    //   this.$emit('input', undefined)
    // }
  },
}
</script>

<style scoped>

</style>
