<template>
  <li
    :class="{
      [styles.air__menuLeft__item]: true,
      [styles.air__menuLeft__submenu]: true,
      [styles.air__menuLeft__submenu__active]: item.key === activeSubmenu,
    }"
  >
    <a
      href="javascript: void(0);"
      :class="styles.air__menuLeft__link"
      @click="() => handleSubmenuClick(item.key)"
      @mouseenter="(event) => handleFlyoutOver(event, item.key, item.children)"
      @mouseleave="handleFlyoutOut(item.key)"
    >
<!--      <i-->
<!--        :class="{-->
<!--          [item.icon]: true,-->
<!--          [styles.air__menuLeft__icon]: true,-->
<!--        }"-->
<!--      />-->
      <b-icon v-if="item.icon" :icon="item.icon" :class="{
          [item.icon]: true,
          [styles.air__menuLeft__icon]: true,
        }"/>
      <span>{{ item.title }}</span>
      <span class="badge text-white bg-blue-light float-right mt-1 px-2">{{ item.count }}</span>
    </a>
    <ul :class="styles.air__menuLeft__list">
      <template v-for="(item, index) in item.children">
        <item
          v-if="!item.children && !item.category && userHasPermission(item.permissions)"
          :key="index"
          :item="item"
          :styles="styles"
          :activeItem="activeItem"
        />
      </template>
    </ul>
  </li>
</template>

<script>
import Item from './item'
import { mapState } from 'vuex'

export default {
  name: 'SubMenu',
  components: { Item },
  props: {
    item: Object,
    styles: Object,
    activeSubmenu: String,
    activeItem: String,
    handleSubmenuClick: Function,
    handleFlyoutOver: Function,
    handleFlyoutOut: Function,
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    userHasPermission(permissions) {
      if (permissions.length === 0) {
        return true
      } else {
        let matches = 0
        let i = 0
        for (i = 0; i < this.user.allPermissionsNames.length; i++) {
          if (permissions.indexOf(this.user.allPermissionsNames[i]) !== -1) { matches++ }
        }
        return matches > 0
      }
    },
  },
}
</script>
