var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{air__layout__grayBackground: _vm.settings.isGrayBackground}},[_c('a-layout',{class:{
      air__layout__contentMaxWidth: _vm.settings.isContentMaxWidth,
      air__layout__appMaxWidth: _vm.settings.isAppMaxWidth,
      air__layout__grayBackground: _vm.settings.isGrayBackground,
      air__layout__squaredBorders: _vm.settings.isSquaredBorders,
      air__layout__cardsShadow: _vm.settings.isCardShadow,
      air__layout__borderless: _vm.settings.isBorderless,
  }},[(_vm.settings.menuLayoutType === 'left')?_c('air-menu-left'):_vm._e(),(_vm.settings.menuLayoutType === 'top')?_c('air-menu-top'):_vm._e(),_c('a-layout',[_c('a-layout-header',{staticClass:"air__layout__header",class:{
          air__layout__fixedHeader: _vm.settings.isTopbarFixed,
          air__layout__headerGray: _vm.settings.isGrayTopbar,
        }},[(_vm.settings.menuLayoutType !== 'top-dark')?_c('air-topbar'):_vm._e(),(_vm.settings.menuLayoutType === 'top-dark')?_c('air-topbar-dark'):_vm._e()],1),_c('a-layout-content',[_c('div',{staticClass:"air__utils__content"},[_c('transition',{attrs:{"name":_vm.settings.routerAnimation,"mode":"out-in"}},[_c('router-view')],1)],1)])],1)],1),_c('GlobalErrorModal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }