<template>
  <b-form-group id="plant-group" label="Planta" label-for="plant">
    <b-form-select v-model="filterValue" :disabled="CanUserSelectAnyPlant()"
                   :options="WorkCenters" @change="ChangeValue()"
                   id="plant" label="Planta" value-field="jsonApi.id" text-field="name">
      <template slot="first">
        <b-form-select-option value="" >Todas las Plantas</b-form-select-option>
      </template>
    </b-form-select>
  </b-form-group>
</template>

<script>
import { getGeneralMixin } from '@/mixins/getGeneralMixin'
import { mapGetters } from 'vuex'

export default {
  name: 'globalPlantFilter',
  mixins: [getGeneralMixin],
  data() {
    return {
      filterValue: '',
      WorkCenters: [],
    }
  },
  computed: {
    ...mapGetters('user', ['userPlantId', 'userPermissions', 'userRoles']),
  },
  watch: {
    filterValue(newValue) {
      this.$emit('filterValue', newValue)
    },
  },
  methods: {
    // Extracción de Información de los combos
    async GetFilters() {
      this.WorkCenters = await this.getSelectData('workCenters', { sort: 'name' })
    },
    ChangeValue() {
      this.$emit('onChangeValue')
    },
    CanUserSelectAnyPlant() {
      // Si retorna TRUE el filtro se bloquea
      // Si retorna FALSE el filtro será editable
      return !this.userRoles.includes('admin')
    },
  },
  async mounted() {
    await this.GetFilters()
    this.filterValue = this.userPlantId ? this.userPlantId
      : this.userRoles.includes('admin') ? '' : 'NO INFO'
  },
}
</script>

<style scoped>

</style>
