import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    sapProgrammingCode: '0',
    servicePlanningId: '0',
    materialGuardsCheck: false,
    destroyDatedAt: undefined,
    destroyHour: undefined,
    clientAssistantCheck: undefined,
    comments: undefined,
    statusEnabled: false,
    contactInfo: {},
    initialCalendarDate: '',
    finalCalendarDate: '',
    clientId: '',
    selectedCatalogs: {
      drivers: 0,
      trucks: 0,
    },
    currentService: {},
    // Para el bloqueo del servicio
    service_planning_status: '',
    // Para el bloqueo del servicio
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    CREATE_SELECTED_CATALOGS_KEY(state, payload) {
      state.selectedCatalogs[payload] = 0
    },
    SET_SELECTED_CATALOGS_KEY(state, payload) {
      const { resource, data } = payload
      state.selectedCatalogs[resource] = data
    },
    RESET_SELECTED_CATALOGS(state) {
      state.selectedCatalogs = {
        drivers: 0,
        trucks: 0,
      }
    },
    SET_SERVICE_PLANNING_STATUS(state, payload) {
      state.service_planning_status = payload
    },
  },
  actions: {
    UPDATE_FOLIO({ commit }, { payload }) {
      const sapProgrammingCode = payload
      commit('SET_STATE', {
        sapProgrammingCode: sapProgrammingCode,
      })
    },
    UPDATE_KEY({ commit }, { payload }) {
      const servicePlanningId = payload
      commit('SET_STATE', {
        servicePlanningId: servicePlanningId,
      })
    },
    UPDATE_CONTACT_INFO({ commit }, { payload }) {
      const contactInfo = payload
      commit('SET_STATE', {
        contactInfo: contactInfo,
      })
    },
    UPDATE_MATERIAL_GUARD_INFO({ commit }, { payload }) {
      commit('SET_STATE', {
        materialGuardsCheck: payload.materialGuardsCheck,
        destroyDatedAt: payload.destroyDatedAt,
        destroyHour: payload.destroyHour,
        clientAssistantCheck: payload.clientAssistantCheck,
        comments: payload.comments,
      })
    },
    RESET_DATA({ commit }) {
      commit('SET_STATE', {
        sapProgrammingCode: '0',
        servicePlanningId: '0',
        materialGuardsCheck: false,
        destroyDatedAt: undefined,
        destroyHour: undefined,
        clientAssistantCheck: undefined,
        comments: undefined,
        statusEnabled: false,
        contactInfo: {},
        initialCalendarDate: '',
        finalCalendarDate: '',
        clientId: '',
        selectedCatalogs: {
          drivers: 0,
          trucks: 0,
        },
        currentService: {},
      })
    },
    SET_CALENDAR_SELECTED_PERIOD ({ commit }, payload) {
      const { start, end } = payload
      commit('SET_STATE', {
        initialCalendarDate: start,
        finalCalendarDate: end,
      })
    },
    SET_CLIENT({ commit }, payload) {
      commit('SET_STATE', {
        clientId: payload,
      })
    },
    SET_SELECTED_CATALOGS({ state, commit }, payload) {
      const { resource, data } = payload
      // const id = data.jsonApi.id
      // Verificar si la llave existe
      // if (!Object.prototype.hasOwnProperty.call(state.selectedCatalogs, resource)) {
      //   // No existe -Se crea
      //   commit('CREATE_SELECTED_CATALOGS_KEY', resource)
      // }
      // Agregar el id
      commit('SET_SELECTED_CATALOGS_KEY', { resource, data })
      // Fin de SET_SELECTED_CATALOGS
    },
    RESET_SELECTED_CATALOGS_KEY({ commit }, payload) {
      commit('CREATE_SELECTED_CATALOGS_KEY', payload)
    },
    RESET_SELECTED_CATALOGS({ commit }) {
      commit('RESET_SELECTED_CATALOGS')
    },
    SET_SERVICEPLANNINGID({ commit }, payload) {
      commit('SET_STATE', {
        servicePlanningId: payload,
      })
    },
    GOTO_SERVICEPLANNINGID({ commit }, payload) {
      const { id, name } = payload
      commit('SET_STATE', {
        servicePlanningId: id,
      })
      router.push({ name: name, params: { folio: id } })
    },
    SET_CURRENT_SERVICE({ commit }, payload) {
      commit('SET_STATE', {
        currentService: payload,
      })
    },

  },
  getters: {
    state: state => state,
    GET_SELECTED_CATALOGS_KEY: state => resource => {
      if (Object.prototype.hasOwnProperty.call(state.selectedCatalogs, resource)) {
        return state.selectedCatalogs[resource]
      } else {
        return 0
      }
    },
    GET_SCT_OF_SELECTED_TRUCK(state) {
      if (Object.prototype.hasOwnProperty.call(state.selectedCatalogs, 'trucks')) {
        if (state.selectedCatalogs.trucks !== 0) {
          if (Object.keys(state.selectedCatalogs.trucks.carrierCompanies).length > 0) {
            return state.selectedCatalogs.trucks.carrierCompanies.sct_record
          } else return ''
        } else return ''
      } else return ''
    },
    GET_SEMARNAT_OF_SELECTED_TRUCK(state) {
      if (Object.prototype.hasOwnProperty.call(state.selectedCatalogs, 'trucks')) {
        if (state.selectedCatalogs.trucks !== 0) {
          if (Object.keys(state.selectedCatalogs.trucks.carrierCompanies).length > 0) {
            return state.selectedCatalogs.trucks.carrierCompanies.environmental_record
          } else return ''
        } else return ''
      } else return ''
    },
    GET_CLIENT(state) {
      return state.clientId
    },
    LOCK_SERVICE(state) {
      return state.service_planning_status === 'Concluido'
    },
  },
}
