<template>
  <a-select v-model="modelValue" :placeholder="placeholder" style="width: 100%"
            @change="onChange($event)"
            :filter-option="filterOption"
            :disabled="disabled"
            :loading="loading" allowClear showSearch>
    <a-select-option  v-for="option in options" :key="option.id" :value="option[OptionValue]" >
      <slot :option="option">
        {{option.name}}
      </slot>
    </a-select-option>
  </a-select>
</template>

<script>
import { BaseProvider } from '@/services/providers/BaseProvider'
const baseProvider = new BaseProvider()
export default {
  name: 'ResourceSelect',
  props: {
    filters: {
      type: Object,
      required: false,
      default: () => {},
    },
    value: {
      default: undefined,
      // type: [String, Number, undefined],
      required: true,
    },
    urlResource: {
      type: String,
      required: true,
    },
    // ----- Not required -------
    placeholder: {
      default: 'Seleccionar',
      type: String,
      required: false,
    },
    OptionValue: {
      default: 'id',
      type: String,
      required: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      baseProvider,
      options: [],
      loading: true,
    }
  },
  watch: {
    filters: 'Fetch',
  },
  computed: {
    modelValue: {
      // getter
      get () {
        return this.value
      },
      // setter
      set (newValue) {
        this.$emit('input', newValue)
      },
    },
  },
  methods: {
    filterOption(input, option) {
      console.log(input, option)
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    onChange(e) {
      this.$emit('change', e)
    },
    async Fetch() {
      this.options = await this.baseProvider.getCombo(this.urlResource, this.filters)
      this.loading = false
    },
  },
  async mounted() {
    await this.Fetch()
  },
}
</script>

<style scoped>

</style>
