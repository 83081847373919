import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    form: {},
    transactionReagents: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    MUTATE_ITEMS: (state, newItem) => {
      state.transactionReagents.push(newItem)
    },
    DELETE_ITEMS: (state, deleteItem) => {
      const i = state.transactionReagents.map(item => item.id).indexOf(deleteItem.id)
      state.transactionReagents.splice(i, 1)
    },
  },
  actions: {
    STORE_REAGENT_DATA({ commit }, newItem) {
      commit('MUTATE_ITEMS', newItem)
    },
    DELETE_REAGENT_ITEM({ commit }, deleteItem) {
      commit('DELETE_ITEMS', deleteItem)
    },
    CLEAN_STATE({ commit }) {
      commit('SET_STATE', {
        form: {},
        transactionReagents: [],
      })
    },
  },
}
