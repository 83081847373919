<template>
  <div :class="$style.topbar">
    <div class="mr-md-4 mr-auto">
      <!-- <air-search /> -->
    </div>
    <div class="mr-auto d-none d-md-block">
      <!-- <air-issues-history /> -->
    </div>
    <div class="mb-0 mr-4 d-xl-block d-none">
      <!-- <air-status /> -->
    </div>
    <div class="mr-4 d-none d-sm-block">
      <!-- <air-language-switcher /> -->
    </div>
    <div class="mr-4 d-none d-sm-block">
      <!-- <air-actions /> -->
    </div>
    <div class="mr-4 d-none d-sm-block">
<!--      <configuration-menu />-->
    </div>
    <div class>
      <air-user-menu />
    </div>
  </div>
</template>

<script>
// import AirSearch from '@/@airui/layout/TopBar/Search'
// import AirIssuesHistory from '@/@airui/layout/TopBar/IssuesHistory'
// import AirStatus from '@/@airui/layout/TopBar/Status'
// import AirLanguageSwitcher from '@/@airui/layout/TopBar/LanguageSwitcher'
// import AirActions from '@/@airui/layout/TopBar/Actions'
import AirUserMenu from '@/@airui/layout/TopBar/UserMenu'
// import ConfigurationMenu from '@/@airui/layout/TopBar/ConfigurationMenu'

export default {
  components: {
    // AirSearch,
    // AirIssuesHistory,
    // AirStatus,
    // AirLanguageSwitcher,
    // AirActions,
    AirUserMenu,
    // ConfigurationMenu,
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
