<template>
  <responsive-button variant="secondary" pill size="sm" text="Cancelar" icon="x" responsive="md" @ClickEvent="WhenClick"/>
</template>

<script>
export default {
  name: 'simpleModalFooter',
  methods: {
    WhenClick() {
      this.$emit('ClickEvent')
    },
  },
}
</script>

<style scoped>

</style>
