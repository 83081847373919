<template>
<!--SELECT -Planta -->
<a-select v-model="workCenterValue" placeholder="Seleccionar Planta"
          :disabled="CanUserSelectAnyPlant()" @change="HandleChange"
            show-search :filter-option="filterOption">
    <a-select-option  v-for="option in WorkCentersCombo" :key="option.id" :value="option.id" >
      {{option.name}}
    </a-select-option>
</a-select>
<!--SELECT -Planta -->
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
import { mapGetters } from 'vuex'
export default {
  name: 'globalPlantSelect',
  mixins: [fractalMixin],
  props: {
    value: {
      type: [String],
      required: false,
    },
  },
  data() {
    return {
      workCenterValue: '',
      WorkCentersCombo: [],
    }
  },
  computed: {
    ...mapGetters('user', ['userPlantId', 'userPermissions', 'userRoles']),
  },
  watch: {
    workCenterValue(newValue) {
      this.$emit('input', newValue)
    },
  },
  methods: {
    async GetFilters() {
      this.WorkCentersCombo = await this.GetResource('workCenters', {
        sortBy: 'name',
        'fields[workCenters]': 'name',
      })
      console.log(this.WorkCentersCombo)
    },
    CanUserSelectAnyPlant() {
      // Si retorna TRUE el filtro se bloquea
      // Si retorna FALSE el filtro será editable
      return !this.userRoles.includes('admin')
    },
    HandleChange() {
      this.$emit('change', this.workCenterValue)
    },
  },
  async mounted() {
    await this.GetFilters()
    if (this.value === undefined) {
      const initialValue = this.userPlantId ? this.userPlantId : undefined
      this.$emit('input', initialValue)
      this.workCenterValue = initialValue
    } else {
      this.$emit('input', this.value)
      this.workCenterValue = this.value
    }
  },
}
</script>

<style scoped>

</style>
