<template>
  <div class="page-title-wrapper" aria-label="Page title">
    <div class="d-none d-sm-block">
      <div class="mb-5">
        <a-breadcrumb :routes="routes">
          <template slot="itemRender" slot-scope="{route, routes }">
            <b-icon v-if="route.bIcon !== ''" :icon="route.bIcon" />
            <span v-if="routes.indexOf(route) === routes.length - 1">
            {{route.breadcrumbName}}
          </span>
            <!--RUtas con o sin params-->
            <template v-else>
              <router-link v-if="route.path !== undefined && typeof (route.path) === 'string'"
                           :to="route.path">
                {{route.breadcrumbName}}
              </router-link>
              <router-link v-else :to="{ name: route.name}">
                {{route.breadcrumbName}}
              </router-link>
            </template>
          </template>
        </a-breadcrumb>
      </div>
    </div>

    <div class="d-sm-none">
      <b-dropdown :text="routes[routes.length-1].breadcrumbName" block variant="info" class="m-2" menu-class="w-100">
        <b-dropdown-item v-for="route in routes" :key="route.name">
          <span v-if="routes.indexOf(route) === routes.length - 1">
            {{route.breadcrumbName}}
          </span>
          <template v-else>
            <router-link v-if="route.path !== undefined && typeof route.path === 'string'"
                         :to="route.path">
              <b-icon v-if="route.bIcon !== ''" :icon="route.bIcon" />
              {{route.breadcrumbName}}
            </router-link>
            <router-link v-else :to="{ name: route.name}">
              <b-icon v-if="route.bIcon !== ''" :icon="route.bIcon" />
              {{route.breadcrumbName}}
            </router-link>
          </template>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: 'my-breadcrumbs',
  props: {
    routes: {
      type: Array,
      required: false,
      default: () => {
        return [
          { name: 'home', breadcrumbName: 'Inicio', bIcon: 'house-fill' },
        ]
      },
    },
  },
}
</script>

<style scoped>

</style>
