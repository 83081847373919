import { objectArray, resolvePagination } from '@/helpers/jsonApiTransformer'
import api from '@/services/axios/index'
import { errorIterator } from '@/helpers/errorManager'
// import { Modal, notification } from 'ant-design-vue'

export const apiRestMixin = {
  data() {
    return {
      defaultTablePageSize: 15,
    }
  },
  methods: {
    // Json Api Universal Functions
    async getJsonApiResources (resource, params = {}) {
      let fetchResponse = []
      await this.$store.dispatch('jv/get', [resource, {
        params: params,
      }])
        .then(response => {
          fetchResponse = objectArray(response)
        })
        // .catch(error => errorIterator(error))
      return fetchResponse
    },
    async getJsonApiSingleResource (resource, id, params = {}) {
      let fetchResponse = {}
      await this.$store.dispatch('jv/get', [`${resource}/${id}`, {
        params: params,
      }])
        .then(response => {
          fetchResponse = response
        })
        // .catch(error => errorIterator(error))
      return fetchResponse
    },
    async getJsonApiTable(paginationEvent = {}, resource, tableObj, params = {}) {
      if (Object.keys(paginationEvent).length !== 0) {
        tableObj.pagination.current = paginationEvent.current
        tableObj.pagination.pageSize = paginationEvent.pageSize
      }

      // Se agregan datos de paginación a la petición
      params['page[number]'] = (Object.keys(paginationEvent).length !== 0)
        ? `${tableObj.pagination.current}`
        : `${tableObj.currentPageValue}`

      params['page[size]'] = `${tableObj.perPageValue}`

      tableObj.loading = true

      await this.$store.dispatch('jv/get', [resource, {
        params: params,
      }])
        .then(response => {
          tableObj.loading = false
          const jsonApiPagination = response.jsonApi.json.meta.page
          const tableData = response
          tableObj.data = objectArray(tableData)
          tableObj.pagination = resolvePagination(jsonApiPagination)
        }).catch((error) => {
          tableObj.loading = false
          errorIterator(error)
        })
    },
    async setJsonApiResource (formObject, action = 'post', params = {}, callback) {
      let setResponse = {}
      if (action !== 'post' && action !== 'patch') return setResponse
      await this.$store.dispatch(`jv/${action}`, [formObject, params])
        .then(response => {
          setResponse = response
          if (callback && typeof callback === 'function') callback(response)
        }).catch((error) => {
          errorIterator(error)
        })
      return setResponse
    },
    async deleteJsonApiResource (deleteObject, params = {}, callback) {
      let setResponse
      await this.$store.dispatch('jv/delete', [deleteObject, params])
        .then(response => {
          setResponse = response
          if (callback && typeof callback === 'function') callback()
        })
        // .catch(error => errorIterator(error))
      return setResponse
    },
    // Simple Axios Universal Functions
    async getAxiosResource(path, params = {}, callback) {
      let fetchResponse = []
      await api.get(path, {
        params: params,
      })
        .then(response => {
          fetchResponse = objectArray(response.data.data)
          if (callback && typeof callback === 'function') callback()
        })
        // .catch(error => errorIterator(error))
      return fetchResponse
    },
    async postAxiosResource(path, request = {}, callback) {
      let setResponse = {}
      await api.post(path, request)
        .then(response => {
          setResponse = response
          if (callback && typeof callback === 'function') callback()
        })
        // .catch(error => errorIterator(error))
      return setResponse
    },
    async putAxiosResource(path, request = {}, callback) {
      let setResponse = {}
      await api.put(path, request)
        .then(response => {
          setResponse = response
          if (callback && typeof callback === 'function') callback()
        })
        // .catch(error => errorIterator(error))
      return setResponse
    },
    async patchAxiosResource(path, request = {}, callback) {
      let setResponse = {}
      await api.patch(path, request)
        .then(response => {
          setResponse = response
          if (callback && typeof callback === 'function') callback()
        })
        // .catch(error => errorIterator(error))
      return setResponse
    },
    async deleteAxiosResource(path, request = {}, callback) {
      let setResponse = {}
      await api.delete(path, request)
        .then(response => {
          setResponse = response
          if (callback && typeof callback === 'function') callback()
        })
        // .catch(error => errorIterator(error))
      return setResponse
    },

  },
}
