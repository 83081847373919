import api from '@/services/axios/index'
// import { errorIterator } from '@/helpers/errorManager'
import { deserialize, serialize } from 'jsonapi-fractal'
import { Modal, notification } from 'ant-design-vue'
import { getPagination, setPagination, getPaginationv2, setPaginationv2 } from '@/helpers/tableHelpers'
import api2 from '@/services/axios/index2'
export const fractalMixin = {
  data() {
    return {
      bvMonthCombo: [
        { value: '1', text: 'Enero' },
        { value: '2', text: 'Febrero' },
        { value: '3', text: 'Marzo' },
        { value: '4', text: 'Abril' },
        { value: '5', text: 'Mayo' },
        { value: '6', text: 'Junio' },
        { value: '7', text: 'Julio' },
        { value: '8', text: 'Agosto' },
        { value: '9', text: 'Septiembre' },
        { value: '10', text: 'Octubre' },
        { value: '11', text: 'Noviembre' },
        { value: '12', text: 'Diciembre' },
      ],
    }
  },
  methods: {
    // Helpers
    bvYearsCombo() {
      const max = new Date().getFullYear()
      const years = []

      for (let i = max; i >= 2020; i--) {
        years.push({ value: i.toString(), text: i.toString() })
      }
      return years
    },
    // Helpers
    // ****** FUNCIONES GENÉRICAS ****** //
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    async errorLogic(error, errorCallback = null) {
      if (errorCallback && typeof errorCallback === 'function') {
        errorCallback(error)
      }
      // errorIterator(error)
      return error
    },
    async successLogic(msg = '', response = null, successCallback = null, errorCallback = null) {
      if (response) {
        notification.success({
          message: msg,
        })
        if (successCallback && typeof successCallback === 'function') {
          successCallback(response.data.data)
        }
        return response
      } else {
        if (errorCallback && typeof errorCallback === 'function') {
          errorCallback()
        }
        return {}
      }
    },
    cleanEmptyValuesFromAForm(form) {
      for (const propName in form) {
        if (form[propName] === null || form[propName] === undefined || form[propName] === '') {
          delete form[propName]
        }
      }
      return form
    },
    createFormData(form) {
      if (typeof form.form === 'object' && Object.keys(form.form).length > 0) {
        return serialize(this.cleanEmptyValuesFromAForm(form.form), form.resourceType, {
          relationships: form.relationships,
          // changeCase: 'camelCase',
        })
      }
      return false
    },
    updateFormData(form) {
      if (typeof form.form === 'object' && Object.keys(form.form).length > 0) {
        return serialize(form.form, form.resourceType, {
          relationships: form.relationships,
          // changeCase: 'camelCase',
        })
      }
      return false
    },
    async deleteQuestion(resourceType, id, successCallback = null, errorCallback = null) {
      const auxThis = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este registro?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        async onOk () {
          await auxThis.DeleteResource(resourceType, id, successCallback, errorCallback)
        },
        onCancel () {
          notification.info({
            message: 'No se ha eliminado el registro',
          })
        },
      })
    },
    // ****** FUNCIONES GENÉRICAS ****** //
    // ****** FUNCIONES PARA JSONAPI ****** //
    async GetResource(path, params = {}, successCallback = null, errorCallback = null) {
      let setResponse = null
      await api.get(path, {
        params: params,
      })
        .then((response) => {
          setResponse = deserialize(response.data)
          if (successCallback && typeof successCallback === 'function') {
            successCallback(setResponse)
          }
        })
        .catch(async (error) => {
          await this.errorLogic(error, errorCallback)
        })
      return setResponse
    },
    async GetTableResource(paginationEvent = {}, resourceType, tableObj, params = {}) {
      setPagination(paginationEvent, tableObj, params)
      tableObj.loading = true

      await api.get(`/${resourceType}`, {
        params: params,
      })
        .then((response) => {
          tableObj.data = deserialize(response.data)
          tableObj.loading = false
          tableObj.pagination = getPagination(response.data.meta.page)
        })
        .catch(async (error) => {
          await this.errorLogic(error)
        })
    },
    async PostResource(form, successCallback = null, errorCallback = null) {
      const requestForm = await this.createFormData(form)

      if (!requestForm) return {}

      let setResponse = {}
      await api.post(`/${form.resourceType}`, requestForm)
        .then(async (response) => {
          setResponse = await this.successLogic(
            'Se ha guardado el registro exitosamente.',
            response,
            successCallback,
            errorCallback,
          )
        })
        .catch(async (error) => {
          await this.errorLogic(error, errorCallback)
        })
      return setResponse
    },
    async PutResource(form, successCallback = null, errorCallback = null) {
      const requestForm = await this.updateFormData(form)
      if (!requestForm) return {}

      let setResponse = {}
      await api.patch(`/${form.resourceType}/${form.form.id}`, requestForm)
        .then(async (response) => {
          setResponse = await this.successLogic(
            'Se ha actualizado el registro exitosamente.',
            response,
            successCallback,
            errorCallback,
          )
        })
        .catch(async (error) => {
          await this.errorLogic(error, errorCallback)
        })
      return setResponse
    },
    async DeleteResource(resourceType, id, successCallback = null, errorCallback = null) {
      let setResponse = {}
      await api.delete(`/${resourceType}/${id}`)
        .then(async (response) => {
          setResponse = await this.successLogic(
            'Se ha eliminado el registro exitosamente.',
            response,
            successCallback,
            errorCallback,
          )
        })
        .catch(async (error) => {
          await this.errorLogic(error, errorCallback)
        })
      return setResponse
    },
    // ****** FUNCIONES PARA JSONAPI ****** //
    // ****** **********************  ****** //
    // ****** **********************  ****** //
    // ****** FUNCIONES BASICAS AXIOS ****** //

    // ****** FUNCIONES BASICAS AXIOS ****** //
    // ****** **********************  ****** //
    // ****** **********************  ****** //
    // ****** FUNCIONES UTILES PARA DROPZONE  ****** //
    async DeleteFile(url, params, successCallback = null, errorCallback = null) {
      await api.post(url, params)
        .then(async (response) => {
          if (successCallback && typeof successCallback === 'function') {
            successCallback(response.data)
          }
        })
        .catch(async (error) => {
          await this.errorLogic(error, errorCallback)
        })
    },
    // ****** FUNCIONES UTILES PARA DROPZONE  ****** //
    // ****** FUNCIONES AXIOS NORMALES  ****** //
    async AxiosPost(url, params, successCallback = null, errorCallback = null) {
      await api.post(url, params)
        .then(async (response) => {
          if (successCallback && typeof successCallback === 'function') {
            successCallback(response.data)
          }
        })
        .catch(async (error) => {
          await this.errorLogic(error, errorCallback)
        })
    },
    async AxiosPut(url, params, successCallback = null, errorCallback = null) {
      await api.put(url, params)
        .then(async (response) => {
          if (successCallback && typeof successCallback === 'function') {
            successCallback(response.data)
          }
        })
        .catch(async (error) => {
          await this.errorLogic(error, errorCallback)
        })
    },
    async AxiosGet(url, params, successCallback = null, errorCallback = null) {
      let setResponse = null
      await api.get(url, {
        params: params,
      })
        .then(async (response) => {
          setResponse = response.data
          if (successCallback && typeof successCallback === 'function') {
            successCallback(response.data)
          }
        })
        .catch(async (error) => {
          await this.errorLogic(error, errorCallback)
        })
      return setResponse
    },
    async AxiosDelete(url, params, successCallback = null, errorCallback = null) {
      await api.delete(url, params)
        .then(async (response) => {
          if (successCallback && typeof successCallback === 'function') {
            successCallback(response.data)
          }
        })
        .catch(async (error) => {
          await this.errorLogic(error, errorCallback)
        })
    },
    // ****** FUNCIONES AXIOS NORMALES  ****** //
    // ****** FUNCIONES PARA Api V2 non JSON API URL ****** //
    async GetConcurrencyResources(paths, successCallback = null, errorCallback = null) {
      const setResponse = []
      const functionsget = []
      // const responseFormatter = new ResponseClass();
      paths.forEach((element, index) => {
        const promisecur = api2.get(paths[index].path, {
          params: paths[index].params,
        }).then(res => ({ res: res, promise: `promise${index}` }))
        functionsget.push(promisecur)
      })
      await Promise.all(functionsget)
        .then((response) => {
          response.forEach((element, ind) => {
            setResponse.push(element.res.data.data.data)
          })
          if (successCallback && typeof successCallback === 'function') {
            successCallback(setResponse)
          }
        })
        .catch(async (error) => {
          await this.errorLogic(error, errorCallback)
        })
      return setResponse
    },
    async GetTableResourcev2(paginationEvent = {}, resourceType, tableObj, params = {}) {
      setPaginationv2(paginationEvent, tableObj, params)
      tableObj.loading = true
      await api2.get(resourceType, { params })
        .then((response) => {
          tableObj.data = response.data.data.data
          tableObj.loading = false
          tableObj.pagination = getPaginationv2(response.data.data)
        })
        .catch(async (error) => {
          await this.errorLogic(error)
        })
    },
    async PostResourcev2(form, successCallback = null, errorCallback = null) {
      if (!form) return {}
      let setResponse = {}
      await api2.post(`/${form.resourceType}`, form.form)
        .then(async (response) => {
          setResponse = await this.successLogic(
            'Se ha guardado el registro exitosamente.',
            response,
            successCallback,
            errorCallback,
          )
        })
        .catch(async (error) => {
          await this.errorLogic(error, errorCallback)
        })
      return setResponse
    },
    async PutResourcev2(form, successCallback = null, errorCallback = null) {
      if (!form) return {}
      let setResponse = {}
      await api2.put(`/${form.resourceType}/${form.form.id}`, form.form)
        .then(async (response) => {
          setResponse = await this.successLogic(
            'Se ha actualizado el registro exitosamente.',
            response,
            successCallback,
            errorCallback,
          )
        })
        .catch(async (error) => {
          await this.errorLogic(error, errorCallback)
        })
      return setResponse
    },
    async DeleteResourcev2(resourceType, id, successCallback = null, errorCallback = null) {
      let setResponse = {}
      await api2.delete(`/${resourceType}/${id}`)
        .then(async (response) => {
          setResponse = await this.successLogic(
            'Se ha eliminado el registro exitosamente.',
            response,
            successCallback,
            errorCallback,
          )
        })
        .catch(async (error) => {
          await this.errorLogic(error, errorCallback)
        })
      return setResponse
    },
    async GetResourcev2(path, params = {}, successCallback = null, errorCallback = null) {
      let setResponse = null
      await api2.get(path, {
        params: params,
      })
        .then((response) => {
          setResponse = response.data
          if (successCallback && typeof successCallback === 'function') {
            successCallback(setResponse)
          }
        })
        .catch(async (error) => {
          await this.errorLogic(error, errorCallback)
        })
      return setResponse
    },
  },
}
