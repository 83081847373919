<template>
  <div>
    <!--Dropzone -->
    <a-form-model-item  :label="label" :ref="domReference" :prop="domReference">
      <div slot="extra" class="text-center">
        Los archivos son guardados automáticamente, no es necesario presionar el boton de Guardar.
      </div>
      <div class="clearfix">
        <a-upload :accept="aceptedFiles" :name="nameOfFile"
                  :data="{
                    'id': id,
                    strategy: strategy,
                    mimetypes: mimetypes,
                    mimes: mimes,
                  }"
                  :action="attachFileUrl"
                  list-type="picture-card"
                  :file-list="value"
                  :before-upload="BeforeUpload"
                  :remove="RemoveFile"
                  @change="UploadFile"
                  @preview="handlePreview"
        >
          <div>
            <b-icon icon="plus"/>
            <div class="ant-upload-text">{{labelBtn}}</div>
          </div>
        </a-upload>
        <!-- MODAL para vista previa de imagen -->
        <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false" :zIndex="3000">
          <img alt="previewFile" style="width: 100%;" :src="previewImage" />
        </a-modal>
        <!-- MODAL para vista previa de imagen -->
      </div>
    </a-form-model-item>
    <!--Dropzone -->
    <!--Alert -->
    <fadeAlert :text="`La imagen cargada es mayor a ${maxMBSize}MB`"
               variant="danger" v-model="fadeAlter"/>
    <!--Alert -->
  </div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
import { validationError } from '@/helpers/errorManager'
// Este componente está diseñado para ser usado dentro de <a-form-model> </a-form-model>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  name: 'filesUpload',
  mixins: [fractalMixin],
  props: {
    // Valores para control de mensajes
    label: {
      default: 'Archivos',
      type: [String],
      required: false,
    },
    labelBtn: {
      default: 'Cargar Archivo',
      type: [String],
      required: false,
    },
    // Valores para referencias de validacion y DOM
    domReference: {
      default: 'dropzone',
      type: [String],
      required: false,
    },
    // Valores de comunicación con API
    strategy: {
      type: [String],
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    // Valor ligado a formulario
    value: {
      type: [Array],
      required: true,
    },
    // Valores de configuración
    nameOfFile: {
      default: 'file',
      type: [String],
      required: false,
    },
    maxMBSize: {
      default: 2,
      type: [Number],
      required: false,
    },
    fileslimit: {
      default: 2,
      // enviar -1 para no limitar
      type: [Number],
      required: false,
    },
    // Tipos de archivos validos para dropzone (soloJS)
    aceptedFiles: {
      default: 'image/*,.pdf',
      type: [String],
      required: false,
    },
    mimetypes: {
      default: '',
      type: [String],
      required: false,
    },
    mimes: {
      default: '',
      type: [String],
      required: false,
    },
  },
  data() {
    return {
      attachFileUrl: `${process.env.VUE_APP_API_URL}/attachFile`,
      fadeAlter: false,
      spinner: false,
      previewVisible: false,
      previewImage: '',
    }
  },
  // watch: {
  //   value(newValue, oldValue) {
  //     if (newValue !== oldValue) {
  //       this.$emit('input', newValue)
  //     }
  //   },
  // },
  methods: {
    async UploadFile(info) {
      const { file } = info
      if (Object.hasOwnProperty.call(file, 'response') && Object.hasOwnProperty.call(file.response, 'files')) {
        this.$emit('input', file.response.files)
        this.onSuccess()
      } else if (Object.hasOwnProperty.call(file, 'response') && Object.hasOwnProperty.call(file.response, 'errors')) {
        // Verificar error
        const errors = file.response.errors
        validationError(errors)
        this.onError()
      }
    },
    BeforeUpload(file) {
      // Confirmacion de tamaño del archivo
      const smallerThanMBLimit = file.size / 1024 / 1024 < this.maxMBSize
      if (!smallerThanMBLimit) {
        this.fadeAlter = true
        this.onError()
        return false
      } else {
        this.$emit('input', [...this.value, {
          uid: file.uid,
          name: file.name,
          status: 'done',
          url: undefined,
        }])
        // Control del # de archivos permitidos
        if (this.fileslimit !== -1) {
          this.value.slice(-this.fileslimit)
        }
        return true
      }
    },
    async RemoveFile(file) {
      if (!(Object.hasOwnProperty.call(file, 'response') && Object.hasOwnProperty.call(file.response, 'errors'))) {
        const params = {
          id: this.id,
          url: file.url,
          strategy: this.strategy,
        }
        await this.DeleteFile('/dettachFile', params, async(resp) => {
          this.$emit('input', resp.files)
          await this.onSuccess()
        })
      }
      return false
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    onSuccess() {
      this.$emit('Success')
    },
    onError() {
      this.$emit('Error')
    },
    test() {
      validationError({})
    },
  },
}
</script>

<style scoped>

</style>
