import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: 'inicio',
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
      },
      children: [
        // ===============  Home
        {
          path: '/testingNewFunctions',
          meta: {
            title: 'TEST',
            permissions: [],
          },
          // component: () => import('./views/geocycle/laboratories/testingNewFunctions'),
          component: () => import('./views/experimeints/newSelect.vue'),
        },
        {
          path: '/inicio',
          name: 'home',
          meta: {
            title: 'Inicio',
            permissions: [],
          },
          component: () => import('./views/geocycle/home'),
        },
        // ===============  Home
        // ===============  Configurations
        // -------- Catalogs
        {
          path: '/catalogos',
          name: 'catalogs',
          meta: {
            title: 'Catálogos',
            permissions: ['catalogs'],
          },
          component: () => import('./views/geocycle/catalogs/main'),
        },
        {
          path: '/catalogos/:table',
          name: 'detail-catalog',
          meta: {
            title: 'Detalle',
            permissions: ['catalogs'],
          },
          component: () => import('./views/geocycle/catalogs/detail'),
        },
        // -------- Catalogs
        // -------- Usuarios
        // Users Table
        {
          path: '/EcoNet-users',
          name: 'admin_users',
          meta: {
            title: 'Administración de Usuarios',
            permissions: ['admin_users'],
          },
          component: () => import('./views/geocycle/user/crud/main'),
        },
        // Users Table
        // User Profile
        {
          path: '/EcoNet-users/:user',
          name: 'detail_user',
          meta: {
            title: 'Administración de Usuarios',
            permissions: ['admin_users'],
          },
          component: () => import('./views/geocycle/user/crud/registerEdit'),
        },
        // User Profile
        // MyProfile
        {
          path: '/perfil-de-usuario',
          name: 'user_profile',
          meta: {
            title: 'Perfil de Usuario',
            permissions: [],
          },
          component: () => import('./views/geocycle/user/profile/index'),
        },
        // MyProfile
        // -------- Usuarios
        // -------- Roles
        // Roles Table
        {
          path: '/EcoNet-roles',
          name: 'admin_roles',
          meta: {
            title: 'Administración de Roles',
            permissions: ['admin_users'],
          },
          component: () => import('./views/geocycle/roles/crud/main'),
        },
        // Roles Table
        // Roles Create-Edit
        {
          path: '/EcoNet-roles/:role',
          name: 'detail_role',
          meta: {
            title: 'Administración de Roles',
            permissions: ['admin_users'],
          },
          component: () => import('./views/geocycle/roles/crud/registerEdit'),
        },
        // Roles Create-Edit
        // -------- Roles
        // -------- SAP
        {
          path: '/sap',
          name: 'sap',
          meta: {
            title: 'SAP',
            permissions: [],
          },
          component: () => import('./views/geocycle/sap/service_planning_saps/servicePlanningSapTable'),
        },
        // -------- SAP
        // -------- Excel
        {
          path: '/excels',
          name: 'excels',
          meta: {
            title: 'Excel',
            permissions: [],
          },
          component: () => import('./views/geocycle/excel/excelMenu/menuExcel'),
        },
        {
          path: '/excels/descarga-de-formatos',
          name: 'excel_download',
          meta: {
            title: 'Descarga de Formatos',
            permissions: [],
          },
          component: () => import('./views/geocycle/excel/excelMenu/excelDownload'),
        },
        {
          path: '/excels/descarga-de-formatos/:table',
          name: 'detail-excel',
          meta: {
            title: 'Detalle',
            permissions: [],
          },
          component: () => import('./views/geocycle/excel/detail'),
        },
        // -------- Excel
        // -------- Configuraciones
        {
          path: '/configuraciones',
          name: 'initial_settings',
          meta: {
            title: 'Configuraciones',
            permissions: [],
          },
          component: () => import('./views/geocycle/initial_settings/main'),
        },
        {
          path: '/configuraciones/:table',
          name: 'detail-settings',
          meta: {
            title: 'Configuración',
            permissions: [],
          },
          component: () => import('./views/geocycle/initial_settings/detail'),
        },
        {
          path: '/check_list_planeacion_de_servicios/:check',
          name: 'service_plannings_checklist',
          meta: {
            title: 'Detalle de Check List',
            permissions: [],
          },
          component: () => import('./views/geocycle/initial_settings/service_plannings_checklist/checkDetail'),
        },
        // ===============  Configurations
        // -------- Manuales
        {
          path: '/manual',
          name: 'handbooks',
          meta: {
            title: 'Manual de Usuario',
            permissions: [],
          },
          component: () => import('./views/geocycle/handbooks/handbookView'),
        },
        // -------- Manuales
        // -------- Incidents
        {
          path: '/creacion_de_incidencias',
          name: 'create-incidents',
          meta: {
            title: 'Control',
            permissions: [],
          },
          component: () => import('./views/geocycle/incidents/create_incidents/createIncidentTable'),
        },
        // -------- Incidents
        // ===============  Planeación de Servicios
        {
          path: '/planeacion-servicios',
          name: 'service_plannings',
          meta: {
            title: 'Planeación de Servicios',
            permissions: [],
          },
          component: () => import('./views/geocycle/service_plannings/mainServicePlannings'),
        },
        {
          path: '/planeacion-servicios-generacion',
          name: 'generation_service_plannings',
          meta: {
            title: 'Calendario',
            permissions: [],
          },
          component: () => import('./views/geocycle/service_plannings/generation_service_plannings/calendarTable'),
        },
        {
          path: '/planeacion-servicios/detalleServicio/:folio/:id',
          name: 'service',
          meta: {
            title: 'Generación del Servicio',
            permissions: [],
          },
          component: () => import('./views/geocycle/service_plannings/generation_service_plannings/serviceDetails'),
        },
        {
          path: '/planeacion-servicios-recepcion/:folio',
          name: 'reception_service_plannings',
          meta: {
            title: 'Recepción del Servicio',
            permissions: [],
          },
          component: () => import('./views/geocycle/service_plannings/reception_service_plannings/reception'),
        },
        {
          path: '/planeacion-servicios-recepcion/pesaje/:carrierID',
          name: 'reception_service_plannings_weighing',
          meta: {
            title: 'Pesaje',
            permissions: [],
          },
          component: () => import('./views/geocycle/service_plannings/reception_service_plannings/weighing'),
        },
        {
          path: '/planeacion-servicios-descarga/:folio',
          name: 'discharge_service_plannings',
          meta: {
            title: 'Descarga de Materiales',
            permissions: [],
          },
          component: () => import('./views/geocycle/service_plannings/discharge_service_plannings/discharge'),
        },
        {
          path: '/planeacion-servicios-descarga/:spId/check/:spCarrierId',
          name: 'check_discharge',
          meta: {
            title: 'Check de Descarga',
            permissions: [],
          },
          component: () => import('./views/geocycle/service_plannings/discharge_service_plannings/check'),
        },
        {
          path: '/control-materiales',
          name: 'material_control',
          meta: {
            title: 'Control de Materiales',
            permissions: [],
          },
          component: () => import('./views/geocycle/service_plannings/material_controls/control'),
        },
        {
          path: '/planeacion-servicios-contabilizacion',
          name: 'accounting_service_plannings',
          meta: {
            title: 'Contabilización del Servicio',
            permissions: [],
          },
          component: () => import('./views/geocycle/service_plannings/accounting_service_plannings/AccountingView'),
        },
        // ===============  Planeación de Servicios
        // ===============  Vigilancia
        {
          path: '/vigilancia',
          name: 'vigilance',
          meta: {
            title: 'Vigilancia',
            permissions: [],
          },
          component: () => import('./views/geocycle/vigilance/vigilanceTable'),
        },
        // ===============  Vigilancia
        // ===============  Operaciones
        {
          path: '/operaciones',
          name: 'operations',
          meta: {
            title: 'Operaciones',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/operationMenu'),
        },
        // -------- Órdenes de Producción
        {
          path: '/ordenes-de-produccion',
          name: 'production_orders',
          meta: {
            title: 'Órdenes de Produción',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/operation/production_order/list'),
        },
        {
          path: '/ordenes-de-produccion/orden/:id?',
          name: 'production_order',
          meta: {
            title: 'Órdenes de Produción',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/operation/production_order/detail'),
        },
        // -------- Almacén de Producto Terminado
        {
          path: '/productos-terminados',
          name: 'finished_products',
          meta: {
            title: 'Operaciones',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/operation/finished_products/main'),
        },
        {
          path: '/manifiesto-producto-terminado/:id',
          name: 'finished-product-manifests',
          meta: {
            title: 'Detalle de Manifiesto',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/operation/finished_products/finishedProductManifests'),
        },
        {
          path: '/impresion-manifiesto-producto-terminado/:id',
          name: 'print-finished-product-manifests',
          meta: {
            title: 'Impresión de Manifiesto',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/operation/finished_products/printFinishedProductManifests'),
        },
        // -------- Almacén de Tambos Vacios
        {
          path: '/tambos-vacios',
          name: 'empty_drums',
          meta: {
            title: 'Operaciones',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/operation/empty_drums/main.vue'),
        },
        // -------- Catálogos de Operaciones
        {
          path: '/catalogos-operaciones',
          name: 'operation_catalogs',
          meta: {
            title: 'Operaciones',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/catalogs/mainCatalogs'),
        },
        // -------- Operations process
        {
          path: '/proceso-operativo',
          name: 'operation_process',
          meta: {
            title: 'Operaciones',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/operation/menu'),
        },
        {
          path: '/formulacion',
          name: 'formulation',
          meta: {
            title: 'Formulación',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/formulations/formulation'),
        },
        {
          path: '/programacion_orden_de_produccion/:id?',
          name: 'production_order_scheduling',
          meta: {
            title: 'Programación Orden de Producción',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/formulations/production_order_scheduling'),
        },
        // -------- trituraciones
        {
          path: '/trituracion/:type/:id',
          name: 'crushing',
          meta: {
            title: 'Trituración',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/crushings/crushingForm'),
        },
        {
          path: '/tiempos_paro/:crushingOperationId',
          name: 'crushingShutdowns',
          meta: {
            title: 'Tiempos de Paro',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/crushings/crushingShutdowns'),
        },
        {
          path: '/trituraciones/:type',
          name: 'crushings',
          meta: {
            title: 'Trituraciones',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/crushings/main'),
        },
        // -------- trituraciones
        {
          path: '/administracion-lotes',
          name: 'operative_batch',
          meta: {
            title: 'Operaciones',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/operation/batches/mainOperativeBatch'),
        },
        {
          path: '/menu-almacen',
          name: 'warehouse_menu',
          meta: {
            title: 'Menú de Almacén',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/warehouses/warehouseMenu'),
        },

        // **********************************************************************
        // **********************************************************************
        // -------- Warehouse Positions
        {
          path: '/operaciones/almacenes/principal_ubicaciones',
          name: 'mainLocations',
          meta: {
            title: 'Visualización principal de ubicaciones',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/warehouses/transactions/mainLocations'),
        },
        // -------- Warehouse Positions
        // **********************************************************************
        // **********************************************************************

        // -------- Operations process
        {
          path: '/operaciones/control_operaciones',
          name: 'operationControl',
          meta: {
            title: 'Control de Operaciones',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/control/controlMenu'),
        },
        // -------- control de personal
        {
          path: '/operaciones/control_personal',
          name: 'personalControl',
          meta: {
            title: 'Control de Personal',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/control/personal/personalMenu'),
        },
        {
          path: '/operaciones/control_asistencias',
          name: 'assistsControl',
          meta: {
            title: 'Control de Asistencias',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/control/personal/assistsControl'),
        },
        {
          path: '/operaciones/administracion_personal',
          name: 'adminPersonal',
          meta: {
            title: 'Administración de Personal',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/control/personal/adminPersonal'),
        },
        {
          path: '/operaciones/reporte_asistencias',
          name: 'assistsReport',
          meta: {
            title: 'Reporte de Asistencia',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/control/personal/assistsReport'),
        },
        // -------- CheckList de equipos de operaciones y seguridad
        {
          path: '/operaciones/equipos/:type',
          name: 'operationEquipments',
          meta: {
            title: 'Equipos de Operaciones',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/control/equipments/operationEquipments'),
        },
        {
          path: '/check_list_equipos/:checksEquipmentSource/:auxiliarMaster/:equipmentCheck?',
          name: 'equipmentChecks',
          meta: {
            title: 'Check List',
            permissions: [],
          },
          component: () => import('./views/geocycle/checkList/equipmentChecks'),
        },
        {
          path: '/historial_equipo/:auxiliarMaster',
          name: 'equipmentChecksHistory',
          meta: {
            title: 'Check List',
            permissions: [],
          },
          component: () => import('./views/geocycle/checkList/equipmentChecksHistory'),
        },
        // -------- control de operaciones
        // -------- warehouses
        {
          path: '/operaciones/almacenes',
          name: 'warehouses',
          meta: {
            title: 'Gestión de Almacenes',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/warehouses/warehouseMain'),
        },
        {
          path: '/operaciones/almacenes/catalogs',
          name: 'warehousesCatalogs',
          meta: {
            title: 'Catálogo de Almacenes',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/warehouses/components/whCatalogs'),
        },
        {
          path: '/operaciones/almacenes/catalogs/:id/:name',
          name: 'warehousesCatalogs1',
          meta: {
            title: 'Almacenes: Niveles y Posiciones',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/warehouses/components/whLevelsPos'),
          props: true,
        },
        {
          path: '/operaciones/almacenes/ubicacciones',
          name: 'locations',
          meta: {
            title: 'Ubicación de elementos',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/warehouses/transactions/locations'),
        },
        {
          path: '/operaciones/almacenes/historial_elementos/:transactionCode',
          name: 'item_history',
          meta: {
            title: 'Historial de elementos',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/warehouses/transactions/item_history'),
        },
        {
          path: '/operaciones/almacenes/transaccion/:type',
          name: 'transactions',
          meta: {
            title: 'Transacciones',
            permissions: [],
          },
          component: () => import('./views/geocycle/operations/warehouses/transactions/transaction'),
        },
        // ===============  Operaciones
        // ===============  Laboratorio
        {
          path: '/laboratorio',
          name: 'laboratories',
          meta: {
            title: 'Laboratorio',
            permissions: [],
          },
          component: () => import('./views/geocycle/laboratories/laboratoryMenu'),
        },
        {
          path: '/laboratorio/inspeccion_visual',
          name: 'visualInspections',
          meta: {
            title: 'Inspección Visual',
            permissions: [],
          },
          component: () => import('./views/geocycle/laboratories/visual_inspection/visualInspectionMain'),
        },
        {
          path: '/laboratorio/recepcion_muestras',
          name: 'sampleReceiptMain',
          meta: {
            title: 'Almacén de Reactivos',
            permissions: [],
          },
          component: () => import('./views/geocycle/laboratories/sample_receipt/main'),
        },
        {
          path: '/laboratorio/detalle_muestras/:id',
          name: 'sampleDetail',
          meta: {
            title: 'Detalle de la Muestra',
            permissions: [],
          },
          component: () => import('./views/geocycle/laboratories/sample_receipt/detail'),
        },
        {
          path: '/laboratorio/plan_muestreo',
          name: 'samplePlanForm',
          meta: {
            title: 'Plan de Muestreo',
            permissions: [],
          },
          component: () => import('./views/geocycle/laboratories/sample_receipt/samplePlanForm'),
        },
        {
          path: '/laboratorio/master_reactivos',
          name: 'reagentsMaster',
          meta: {
            title: 'Master de Reactivos',
            permissions: [],
          },
          component: () => import('./views/geocycle/laboratories/reagents/reagentsMasterPage'),
        },
        {
          path: '/laboratorio/master_análisis',
          name: 'testMaster',
          meta: {
            title: 'Master de Análisis',
            permissions: [],
          },
          component: () => import('./views/geocycle/laboratories/analysis_master/testMaster'),
        },
        {
          path: '/laboratorio/analisis_muestras',
          name: 'labAnalisis',
          meta: {
            title: 'Análisis de Laboratorio',
            permissions: [],
          },
          component: () => import('./views/geocycle/laboratories/analysis/mainSampleAnalisis'),
        },
        {
          path: '/laboratorio/master_equipos_laboratorio',
          name: 'laboratoryEquipment',
          meta: {
            title: 'Master de Equipos de Laboratorio',
            permissions: [],
          },
          component: () => import('./views/geocycle/laboratories/equipments/master/laboratoryEquipment'),
        },
        {
          path: '/laboratorio/almacen_reactivos',
          name: 'reagentMain',
          meta: {
            title: 'Almacén de Reactivos',
            permissions: [],
          },
          component: () => import('./views/geocycle/laboratories/reagent_warehouse/reagentMain'),
        },
        {
          path: '/laboratorio/entradas_reactivos',
          name: 'reagentStorageEntrance',
          meta: {
            title: 'Entrada de Reactivos',
            permissions: [],
          },
          component: () => import('./views/geocycle/laboratories/reagent_warehouse/storageEntrance'),
        },
        {
          path: '/laboratorio/salidas_reactivos',
          name: 'reagentStorageOutput',
          meta: {
            title: 'Salida de Reactivos',
            permissions: [],
          },
          component: () => import('./views/geocycle/laboratories/reagent_warehouse/storageOutput'),
        },
        {
          path: '/laboratorio/almacen_muestras',
          name: 'sampleWarehouseMain',
          meta: {
            title: 'Almacén de Muestras',
            permissions: [],
          },
          component: () => import('./views/geocycle/laboratories/sample_warehouse/sampleWarehouseMain'),
        },
        {
          path: '/laboratorio/reactivo/:reagentId',
          name: 'reagentAuxiliars',
          meta: {
            title: 'Detalle de Reactivo',
            permissions: [],
          },
          component: () => import('./views/geocycle/laboratories/warehouses/reagents/reagentAuxiliars'),
        },
        // ===============  Laboratorio
        // Administration
        // Administración
        {
          path: '/administracion_menu',
          name: 'administration_menu',
          meta: {
            title: 'menú de Administración',
            permissions: [],
          },
          component: () => import('./views/geocycle/administrations/administrationMenu'),
        },
        {
          path: '/administracion',
          name: 'administrations',
          meta: {
            title: 'Administración',
            permissions: [],
          },
          component: () => import('./views/geocycle/administrations/BillingService'),
        },
        // ===============  Reportes
        {
          path: '/reportes',
          name: 'reports',
          meta: {
            title: 'Reportes',
            permissions: [],
          },
          component: () => import('./views/geocycle/reports/reportMenu'),
        },
        {
          path: '/reportes/reportes_operaciones',
          name: 'operation_reports',
          meta: {
            title: 'Reportes Operaciones',
            permissions: [],
          },
          component: () => import('./views/geocycle/reports/operation/operationMenu'),
        },
        {
          path: '/reportes/reportes_operaciones/almacen',
          name: 'warehouse_report',
          meta: {
            title: 'Reportes Operaciones',
            permissions: [],
          },
          component: () => import('./views/geocycle/reports/operation/warehouseReport'),
        },
        {
          path: '/reportes/reportes_logistica',
          name: 'LogisticReportMenu',
          meta: {
            title: 'Reportes Logística',
            permissions: [],
          },
          component: () => import('./views/geocycle/reports/logistics/logisticMenu'),
        },
        {
          path: '/reportes/reportes_logistica/reporte_pesaje',
          name: 'weighingReport',
          meta: {
            title: 'Reporte de Pesaje',
            permissions: [],
          },
          component: () => import('./views/geocycle/reports/logistics/weighingReport'),
        },
        {
          path: '/reportes/planeacion_servicios',
          name: 'ServicePlanningReport',
          meta: {
            title: 'Reporte Planeación de Servicios',
            permissions: [],
          },
          component: () => import('./views/geocycle/reports/servicePlanning/controlServicePlanning'),
        },
        // ===============  Reportes
      ],
    },
    // Printable pages (without left menu and top bar)
    {
      path: '/check_list_reporte/:checkListFormId/:checkListFormAuxId?',
      name: 'check_list_report',
      meta: {
        title: 'Reporte de Check List',
        permissions: [],
      },
      component: () => import('./views/geocycle/reports/spCheckReport'),
    },
    {
      path: '/laboratorio/inspeccion_visual/print/:id',
      name: 'visualInspectionPrint',
      meta: {
        title: 'Inspección Visual',
        permissions: [],
      },
      component: () => import('./views/geocycle/reports/prints/visualInspection'),
    },
    {
      path: '/laboratorio/almacen_muestras/:id',
      name: 'testWarehouseQR',
      meta: {
        title: 'Detalle Almacén de muestra',
        permissions: [],
      },
      component: () => import('./views/geocycle/reports/prints/testWarehouseQR'),
    },
    {
      path: '/ordenes-de-produccion/orden/taxInspection/:id',
      name: 'taxInspectionprint',
      meta: {
        title: 'Inspección Fiscal',
        permissions: [],
      },
      component: () => import('./views/geocycle/reports/prints/taxInspectionprint'),
    },
    // System Pages
    {
      path: '/auth',
      component: AuthLayout,
      redirect: 'auth/login',
      children: [
        {
          path: '/auth/404',
          meta: {
            title: 'Error 404',
          },
          component: () => import('./views/auth/404'),
        },
        {
          path: '/auth/500',
          meta: {
            title: 'Error 500',
          },
          component: () => import('./views/auth/500'),
        },
        {
          path: '/auth/login',
          name: 'login',
          meta: {
            title: 'Acceso',
          },
          component: () => import('./views/auth/geocycle/login/login'),
        },
        {
          path: '/auth/login/:provider/*',
          name: 'login_socialite',
          meta: {
            title: 'Acceso',
          },
          component: () => import('./views/auth/geocycle/login/socialiteLogin'),
        },
        {
          path: '/auth/register',
          name: 'register',
          meta: {
            title: 'Sign Up',
          },
          component: () => import('./views/auth/geocycle/register'),
        },
        {
          path: '/auth/reset-password/*',
          name: 'reset_password',
          meta: {
            title: 'Reestablecer Contraseña',
          },
          component: () => import('./views/auth/geocycle/reset-password'),
        },
        {
          path: '/auth/forgot-password',
          name: 'forgot_password',
          meta: {
            title: 'Olvidé mi Contraseña',
          },
          component: () => import('./views/auth/geocycle/forgot-password'),
        },
        {
          path: '/auth/lockscreen',
          meta: {
            title: 'Lockscreen',
          },
          component: () => import('./views/auth/lockscreen'),
        },
      ],
    },
    // Redirect to 404
    {
      path: '*', redirect: 'auth/404', hidden: true,
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    // Si se accede a una ruta que requiera authenticación
    if (!store.state.user.authorized) {
      // No está autorizado(vuex) - redirecciona a auth
      next({
        path: '/auth/login',
        query: { redirect: to.fullPath },
      })
    } else {
      // Si está autorizado(vuex) -- aplica logica de permisos
      // Si tiene el permiso necesario para la acceder a la vista
      if (to.meta.permissions.length === 0) {
        next()
      } else {
        let matches = 0
        let i = 0
        for (i = 0; i < store.state.user.allPermissionsNames.length; i++) {
          if (to.meta.permissions.indexOf(store.state.user.allPermissionsNames[i]) !== -1) { matches++ }
        }
        if (matches > 0) {
          next()
        } else {
          Vue.prototype.$notification.error({
            message: 'Acceso Denegado',
            description: `No cuenta con los permisos necesarios para acceder a ${to.path}, contacte a un administrador para solicitar los permisos necesarios.`,
          })
          next({
            name: 'home',
          })
        }
      }
    }
  } else {
    // Petición para acceder a ruta no protegida
    const authRoutes = ['login', 'login_socialite', 'register', 'reset_password', 'forgot_password']
    if (authRoutes.includes(to.name) && store.state.user.authorized) {
      next({
        name: 'home',
      })
    } else {
      next()
    }
  }
})

export default router
