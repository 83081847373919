<template>
  <a-layout>
    <a-layout-content>
      <!-- <air-sidebar /> -->
      <div
        :class="{
          [$style.container]: true,
          cui__layout__squaredBorders: settings.isSquaredBorders,
          cui__layout__cardsShadow: settings.isCardShadow,
          cui__layout__borderless: settings.isBorderless,
          [$style.white]: settings.authPagesColor === 'white',
          [$style.gray]: settings.authPagesColor === 'gray',
        }"
        :style="{backgroundImage: settings.authPagesColor === 'image' ? `url(resources/images/content/photos/8.jpg)` : 'none'}"
      >
        <div class="pt-3 pb-5 d-flex align-items-end mt-auto">
          <a href="#" :class="$style.logo">

              <img src="/resources/images/avatars/geocycle_img.jpg" class="img-fluid" alt="Logo">

            <!-- <div :class="$style.letter">
              <img src="/resources/images/avatars/geocycle_img.jpg" alt="Logo">
            </div> -->
          </a>
        </div>
        <div class="pt-5 pb-3 d-flex align-items-end mt-auto">
          <div>
<!--            <h2>{{settings.description}} <strong>{{settings.logo}}</strong></h2>-->
            <h2><strong>{{settings.logo}}</strong></h2>
          </div>
        </div>
        <div :class="$style.containerInner">
          <transition :name="settings.routerAnimation" mode="out-in">
            <router-view />
          </transition>
        </div>
        <div class="mt-auto pb-5 pt-5">
          <ul
            class="list-unstyled d-flex mb-0 flex-wrap justify-content-center"
            :class="[$style.footerNav]"
          >
            <li class="list-inline-item">
              <a href="/resources/general_files/termCondi.pdf" target="_blank">Términos y Condiciones de uso</a>
            </li>
            <li class="active list-inline-item">
              <a href="/resources/general_files/conformidad.pdf" target="_blank">Conformidad</a>
            </li>
            <li class="list-inline-item">
              <a href="/resources/general_files/polPrivacidad.pdf" target="_blank">Información Confidencial</a>
            </li>
            <li class="list-inline-item">
              <a href="/resources/general_files/polPrivacidad.pdf" target="_blank">Política de Privacidad</a>
            </li>
          </ul>
          <div class="text-center">
            <strong>
              Todos los derechos reservados {{ moment().format('YYYY') }} © Geocycle México
            </strong>
          </div>
        </div>
      </div>
    </a-layout-content>
    <GlobalErrorModal/>
  </a-layout>
</template>

<script>
import { mapState } from 'vuex'
import GlobalErrorModal from '@/components/GlobalErrorModal'
// import AirSidebar from '@/@airui/layout/Sidebar'

export default {
  name: 'AuthLayout',
  components: {
    GlobalErrorModal,
    // AirSidebar
  },
  computed: mapState(['settings']),
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
