import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import settings from './settings'
import servicePlannings from './service-plannings/planning/index'
import receptionServicePlannings from './service-plannings/reception/index'
import reagents from './reagents/index'
import warehouse from './warehouse/index'
import productionOrders from './productionOrders/index'

// -----  JsonApi spec -----//
import api from '../services/axios/index'
import { jsonapiModule } from 'jsonapi-vuex'
// -----  JsonApi spec -----//

// vuex-persistedstate & Encrypt
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'

const ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

const config = {
  jvtag: 'jsonApi',
  preserveJson: true,
  // clearOnUpdate: true,
  // cleanPatch: true,
  // recurseRelationships: true,
  // followRelationshipsData: false,
}

export default new Vuex.Store({
  state: {
    showErrorModal: false,
    errors: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    SHOW_ERRORS_MODAL(state, payload) {
      state.showErrorModal = true
      state.errors = payload
    },
    HIDE_ERRORS_MODAL(state) {
      state.showErrorModal = false
      state.errors = []
    },
  },
  actions: {},
  modules: {
    user,
    settings,
    servicePlannings,
    receptionServicePlannings,
    reagents,
    warehouse,
    productionOrders,
    jv: jsonapiModule(api, config),
  },
  plugins: [
    createPersistedState({
      paths: ['user'],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
})
