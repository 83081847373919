<template>
  <b-button
    :block="block"
    :disabled="disabled"
    :size="(size == null) ? responsive : size"
    :variant="variant"
    :type="type"
    :tag="tag"
    :pill="pill"
    :squared="squared"
    :pressed="pressed"
    :href="href"
    :rel="rel"
    :target="target"
    :active="active"
    :to="to"
    :append="append"
    :replace="replace"
    :activeClass="activeClass"
    :exact="exact"
    :exactActiveClass="exactActiveClass"
    :prefetch="prefetch"
    :noPrefetch="noPrefetch"
    :routerComponentName="routerComponentName"
    @click="WhenClick"
  >
    <div :class="'d-none d-' +responsive+'-block'" v-if="text != '' ">
      <b-icon v-if="icon != '' "  :class="iconClass"
              :icon="icon"
              :iconVariant="iconVariant"
              :font-scale="iconFontScale"
              :scale="iconScale"
              :rotate="iconRotate"
              :flip-h="iconFlipH"
              :flip-v="iconFlipV"
              :shift-h="iconShiftH"
              :shift-v="iconShiftV"
              :animation="iconAnimation"
              :stacked="iconStacked"
      />
      &nbsp;
      <i v-if="fontAwesome !=null" :class="`${fontAwesome} ${fontAwesomeClass}`"></i>
      &nbsp;
      <span :class="textClass"> {{text}}</span>
      <div v-if="rawHtml!= '' " v-html="rawHtml"/>
    </div>

    <div :class="'d-none d-' +responsive+'-block'" v-else v-b-tooltip.hover :title="(tooltip != '' )? tooltip : text">
      <b-icon v-if="icon != '' "  :class="iconClass"
              :icon="icon"
              :iconVariant="iconVariant"
              :font-scale="iconFontScale"
              :scale="iconScale"
              :rotate="iconRotate"
              :flip-h="iconFlipH"
              :flip-v="iconFlipV"
              :shift-h="iconShiftH"
              :shift-v="iconShiftV"
              :animation="iconAnimation"
              :stacked="iconStacked"
      />
      &nbsp;
      <i v-if="fontAwesome !=null" :class="`${fontAwesome} ${fontAwesomeClass}`"></i>
      &nbsp;
      <span :class="textClass"> {{text}}</span>
      <div v-if="rawHtml!= '' " v-html="rawHtml"/>
    </div>

    <div :class="'d-'+responsive+'-none'" v-b-tooltip.hover :title="(tooltip != '')? tooltip : text">
      <b-icon v-if="icon != '' "  :class="iconClass"
              :icon="icon"
              :iconVariant="iconVariant"
              :font-scale="iconFontScale"
              :scale="iconScale"
              :rotate="iconRotate"
              :flip-h="iconFlipH"
              :flip-v="iconFlipV"
              :shift-h="iconShiftH"
              :shift-v="iconShiftV"
              :animation="iconAnimation"
              :stacked="iconStacked"
      />
      &nbsp;
      <i v-if="fontAwesome !=null" :class="`${fontAwesome} ${fontAwesomeClass}`"></i>
      &nbsp;
      <div v-if="rawHtml!= '' " v-html="rawHtml"/>
    </div>
  </b-button>
</template>

<script>
export default {
  name: 'responsive-button',
  props: {
    // Texto
    text: {
      default: '',
      type: [String],
      required: true,
    },
    textClass: {
      default: '',
      type: [String],
      required: false,
    },
    tooltip: {
      default: '',
      type: [String],
      required: false,
    },
    // Responsive
    responsive: {
      validator: function (value) {
        // El valor debe coincidir con una de estas cadenas de texto
        return ['sm', 'md', 'lg', 'xl'].indexOf(value) !== -1
      },
      default: 'md',
      type: [String],
      required: true,
    },
    // b-icon props
    icon: {
      default: '',
      type: [String],
      required: false,
    },
    iconVariant: {
      default: null,
      type: [String],
      required: false,
    },
    iconFontScale: {
      default: 1,
      type: [String, Number],
      required: false,
    },
    iconScale: {
      default: 1,
      type: [String, Number],
      required: false,
    },
    iconRotate: {
      default: 0,
      type: [String, Number],
      required: false,
    },
    iconFlipH: {
      default: false,
      type: [Boolean],
      required: false,
    },
    iconFlipV: {
      default: false,
      type: [Boolean],
      required: false,
    },
    iconShiftH: {
      default: 0,
      type: [String, Number],
      required: false,
    },
    iconShiftV: {
      default: 0,
      type: [String, Number],
      required: false,
    },
    iconAnimation: {
      default: null,
      type: [String],
      required: false,
    },
    iconStacked: {
      default: false,
      type: [Boolean],
      required: false,
    },
    iconClass: {
      default: '',
      type: [String],
      required: false,
    },
    // FontAwesome (not implemented)
    fontAwesome: {
      default: null,
      type: [String],
      required: false,
    },
    fontAwesomeClass: {
      default: '',
      type: [String],
      required: false,
    },
    // HTML
    rawHtml: {
      default: '',
      type: [String],
      required: false,
    },
    // b-button props
    block: {
      default: false,
      type: [Boolean],
      required: false,
    },
    disabled: {
      default: false,
      type: [Boolean],
      required: false,
    },
    size: {
      default: null,
      type: [String],
      required: false,
    },
    variant: {
      default: 'secondary',
      type: [String],
      required: false,
    },
    type: {
      default: 'button',
      type: [String],
      required: false,
    },
    tag: {
      default: 'button',
      type: [String],
      required: false,
    },
    pill: {
      default: false,
      type: [Boolean],
      required: false,
    },
    squared: {
      default: false,
      type: [Boolean],
      required: false,
    },
    pressed: {
      default: null,
      type: [Boolean],
      required: false,
    },
    href: {
      type: [String],
      required: false,
    },
    rel: {
      default: null,
      type: [String],
      required: false,
    },
    target: {
      default: '_self',
      type: [String],
      required: false,
    },
    active: {
      default: false,
      type: [Boolean],
      required: false,
    },
    to: {
      type: [String, Object],
      required: false,
    },
    append: {
      default: false,
      type: [Boolean],
      required: false,
    },
    replace: {
      default: false,
      type: [Boolean],
      required: false,
    },
    activeClass: {
      type: [String],
      required: false,
    },
    exact: {
      default: false,
      type: [Boolean],
      required: false,
    },
    exactActiveClass: {
      type: [String],
      required: false,
    },
    prefetch: {
      default: null,
      type: [Boolean],
      required: false,
    },
    noPrefetch: {
      default: false,
      type: [Boolean],
      required: false,
    },
    routerComponentName: {
      type: [String],
      required: false,
    },
  },
  methods: {
    WhenClick() {
      this.$emit('ClickEvent')
    },
  },
}
</script>
