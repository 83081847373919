import api2 from '@/services/axios/index2'

export class ApiV2Provider {
  async Get(path, params) {
    let response = {}

    const fetchPromise = api2.get(path, { params }).then(({ data }) => (data.data))

    await fetchPromise.then(({ total, data }) => {
      response = { total, data }
    }).catch((error) => (error))
    return response
  }
}
