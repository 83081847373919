<template>
  <a-modal v-model="errorModal" :afterClose="closeModal"
           :bodyStyle="{ with:'416px', height:'auto'}"
           :closable="false"
           :zIndex="3100" :footer="null">
    <b-container fluid>
      <div class="row mb-3">
        <h5>
          <b-icon icon="x-circle" variant="danger" font-scale="1.25"/>
          <span class="ml-3" style="color: #222222;font-size: 17px;margin-left: 2px;">Error de Validación</span>
        </h5>
      </div>
      <b-list-group>
        <b-list-group-item v-for="(item, index) in errors" :key="index">
          {{item.detail}}
        </b-list-group-item>
      </b-list-group>
      <div class="row justify-content-end mt-3 mr-1">
        <a-button type="primary" @click="closeModal">
          Aceptar
        </a-button>
      </div>
    </b-container>
  </a-modal>
</template>

<script>
export default {
  name: 'GlobalErrorModal',
  computed: {
    errorModal: {
      // getter
      get() {
        return this.$store.state.showErrorModal
      },
      // setter
      set(newValue) {
        this.$store.state.showErrorModal = newValue
      },
    },
    errors() {
      return this.$store.state.errors
    },
  },
  methods: {
    closeModal() {
      this.$store.commit('HIDE_ERRORS_MODAL')
    },
  },
}
</script>

<style scoped>

</style>
