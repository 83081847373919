import api from '@/services/axios/index'
import { deserialize } from 'jsonapi-fractal'

export class BaseProvider {
  async getCombo(url, filters = {}) {
    let data = []

    await api.get(url, { params: filters }).then(response => {
      data = deserialize(response.data)
    })

    return data
  }
}
