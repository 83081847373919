
//  Servicio Ligado a Sanctum de Laravel
import axios from 'axios'
import apiClient from '@/services/axios'
import { errorIterator } from '@/helpers/errorManager'
const authApi = axios.create({
  baseURL: process.env.VUE_APP_API_AUTH,
})
authApi.interceptors.response.use(undefined, error => {
  errorIterator(error)
})

const csrfFunction = async() => {
  return await axios.get(process.env.VUE_APP_CSRF_URL)
}
const loginFunction = async(email, password) => {
  return await authApi.post(process.env.VUE_APP_API_URL + '/login', {
    email,
    password,
  })
}
const registerFunction = async(email, password, name, passwordConfirmation) => {
  return await authApi.post(process.env.VUE_APP_API_URL + '/register', {
    name,
    email,
    password,
    password_confirmation: passwordConfirmation,
  })
}
const socialite = async(provider, code) => {
  return await axios.get(process.env.VUE_APP_API_URL + `/socialiteLogin/${provider}/callback?code=${code}`)
}
export async function login(email, password) {
  return Promise.all([csrfFunction(), loginFunction(email, password)])
    .then((results) => {
      // const csrf = results[0]
      const response = results[1]
      if (response) {
        return response.data
      }
      return false
    })
}

export async function loginSocialite(provider) {
  return axios.get(process.env.VUE_APP_API_URL + '/socialiteLogin/' + provider)
    .then((results) => {
      if (results) {
        // window.location.href = results.data.url
        return results.data
      }
      return false
    })
    .catch(() => false)
}
export async function loginSocialiteCallback(provider, code) {
  return Promise.all([csrfFunction(), socialite(provider, code)])
    .then((results) => {
      const response = results[1]
      if (response) {
        return response.data
      }
      return false
    })
    .catch(() => false)
}

export async function currentAccount() {
  return apiClient
    .get('/user')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(() => false)
}

export async function logout() {
  return apiClient
    .post('/logout')
    .then(() => {
      return true
    })
    .catch(err => {
      console.log('logout', err)
      return false
    })
}

export function updateProfile(name, email, avatar) {
  return apiClient
    .put('/user/profile-information', {
      name: name,
      email: email,
      profile_photo_path: avatar,
    })
    .then(response => {
      if (response) {
        console.log('Update Sanctum', response)
        return response.data
      }
      return false
    })
    .catch(() => false)
}

export async function register(email, password, name, passwordConfirmation) {
  return Promise.all([csrfFunction(), registerFunction(email, password, name, passwordConfirmation)])
    .then((results) => {
      // const csrf = results[0]
      const response = results[1]
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => {
      console.log('register', err)
      return false
    })
}
