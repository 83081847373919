import axios from 'axios'
import { errorIterator } from '@/helpers/errorManager'

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/vnd.api+json',
    Accept: 'application/vnd.api+json',
    // 'Transfer-Encoding': 'chunked',
    'Cache-Control': 'no-store, no-cache, must-revalidate, proxy-revalidate, max-age=0',
    // 'Access-Control-Allow-Origin': 'https://econet.mx',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
    'X-RateLimit-Limit': 60,
    'X-RateLimit-Remaining': 59,
    'Content-Encoding': 'gzip',
  },
})

// TODO Migrar errores a una clase externa con llave - valor (-status-message)
// Errors handling
api.interceptors.response.use(undefined, (error) => {
  errorIterator(error)
})

// for dictionary traductions
// The password must be at least 8 characters.

export default api
