<template>
  <div class="row justify-content-center pt-3" v-show="load">
    <a-spin tip="Cargando..." >
      <div class="spin-content">Espere por favor...</div>
    </a-spin>
<!--    <b-col md="12" class="mb-3 text-center">-->
<!--      <p>Procesando información ...</p>-->
<!--      <b-icon icon="arrow-clockwise" animation="spin-pulse" font-scale="4" variant="primary"></b-icon>-->
<!--    </b-col>-->
  </div>
</template>

<script>
export default {
  name: 'my-spinner',
  props: {
    load: {
      default: false,
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style scoped>
.spin-content {
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  padding: 30px;
}
</style>
