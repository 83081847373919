import { objectArray, resolvePagination } from '@/helpers/jsonApiTransformer'
import { apiRestMixin } from '@/mixins/apiRestMixin'
import { mapActions, mapGetters } from 'vuex'
export const getGeneralMixin = {
  mixins: [
    apiRestMixin,
  ],
  data() {
    return {
      // Catalogos
      incidentableTypes: [
        { value: 'servicePlannings', text: 'Servicio' },
        { value: 'servicePlanningsCarriers', text: 'Servicio de Transporte' },
        { value: 'dischargeMaterials', text: 'Residuo en Descarga' },
      ],
      generalResponse: [],
      specificResponse: {},
      generalAntTable: {
        data: [],
        columns: [],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      comboData: [],
      servicePlanningStatus: [
        'Pendiente',
        'En Plataforma',
        'Manifiestos Cerrados',
        'Concluido',
        'Con Incidencia',
        'Reprogramación',
        'Rechazado',
        'Exceso de Permanencia',
      ],
      serviceSap_status: ['Pendiente', 'Programado', 'Rechazado'],
      serviceStatus: ['Programado', 'No Programado'],
    }
  },
  computed: {
    ...mapGetters('servicePlannings', ['LOCK_SERVICE']),
  },
  methods: {
    ...mapActions('receptionServicePlannings', ['SET_CHECKSTRUCTURE', 'SET_SERVICEID', 'RESET_CHECKSTRUCTURE',
      'REMOVE_CHECKSTRUCTURE_KEY']),
    async getResourceData (params = { sort: 'name' }, resource) {
      await this.$store.dispatch('jv/get', [resource, {
        params: params,
      }])
        .then(response => {
          const tableData = response
          this.generalResponse = objectArray(tableData)
        })
    },
    async getResourceDataWithoutSort (params = { }, resource) {
      await this.$store.dispatch('jv/get', [resource, {
        params: params,
      }])
        .then(response => {
          const tableData = response
          this.generalResponse = objectArray(tableData)
        })
    },
    async getComboData (params = { sort: 'name' }, resource) {
      await this.$store.dispatch('jv/get', [resource, {
        params: params,
      }])
        .then(response => {
          const tableData = response
          this.comboData = objectArray(tableData)
        })
    },
    async getSelectData (resource, params = { sort: 'name' }) {
      let selectResponse
      await this.$store.dispatch('jv/get', [resource, {
        params: params,
      }])
        .then(response => {
          selectResponse = objectArray(response)
        }).catch(() => {
          // TODO: Agregar diccionario de errores
          console.error('An error has occurred, may be the catalog is empty')
        })
      return selectResponse
    },
    async getSpecificResourceData(params = { sort: 'name' }, resource, id) {
      await this.$store.dispatch('jv/get', [`${resource}/${id}`, {
        params: params,
      }])
        .then((response) => {
          const tableData = response
          this.specificResponse = tableData
        })
    },
    async getGeneralAntTable(paginationEvent = {}, resource, params = {}, tableObj) {
      if (Object.keys(paginationEvent).length !== 0) {
        tableObj.pagination.current = paginationEvent.current
        tableObj.pagination.pageSize = paginationEvent.pageSize
      }

      tableObj.loading = true
      await this.$store.dispatch('jv/get', [resource, {
        params: params,
      }])
        .then(response => {
          tableObj.loading = false
          const jsonApiPagination = response.jsonApi.json.meta.page
          const tableData = response
          tableObj.data = objectArray(tableData)
          tableObj.pagination = resolvePagination(jsonApiPagination)
        })
        .catch(() => {
          tableObj.loading = false
        })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    async GET_CHECK(checKey) {
      const params = {
        include: 'checkLists.checkFields,servicePlanningsSubstages',
      }
      const self = {}
      let response = true
      await this.$store.dispatch('jv/get', [`checks/${checKey}`, {
        params: params,
      }])
        .then(response => {
          self.key = response.jsonApi.id
          self.name = response.name
          self.servicePlanningsSubstageRelation = response.servicePlanningsSubstages
          self.servicePlanningsSubstageRelationKey = response.servicePlanningsSubstages.jsonApi.id
          this.SET_CHECKSTRUCTURE({
            check: checKey,
            self,
            data: response.checkLists,
          })
        })
        .catch(() => {
          response = false
          console.info('El check No existe')
        })
      return response
    },
    async getServiceAndSetInState(serviceCode) {
      let params = {
        include: 'workCenters,servicePlanningsMaterials.currents,serviceTypes,servicePlanningsPeriods,shifts,service_planeable,spcomercialCarriers.logisticsShipmentTypes',
      }
      let service = await this.getJsonApiSingleResource('servicePlannings', serviceCode, params)
      // Bloqueo de Servicio en caso de service_planning_status Concluido
      this.$store.commit('servicePlannings/SET_SERVICE_PLANNING_STATUS', service.service_planning_status)
      // Bloqueo de Servicio en caso de service_planning_status Concluido
      if (service.serviceTypes.name === 'Entrada por Compra' || service.serviceTypes.name === 'Entrada por Venta') {
        params = {
          include: 'workCenters,servicePlanningsMaterials.currents,serviceTypes,servicePlanningsPeriods,shifts,spcomercialCarriers.logisticsShipmentTypes,service_planeable.clients',
        }
        service = await this.getJsonApiSingleResource('servicePlannings', serviceCode, params)
      }
      await this.$store.dispatch('servicePlannings/SET_CURRENT_SERVICE', service)
    },

  },
}
