
// -----  HELPERS:  -----//

export const setPagination = (paginationEvent = {}, tableObj, params = {}) => {
  // Se escribe los nuevos datos en el obj de paginacion de la tabla
  if (Object.keys(paginationEvent).length !== 0) {
    tableObj.pagination.current = paginationEvent.current
    tableObj.pagination.pageSize = paginationEvent.pageSize
  }
  // Se agregan datos de paginación a la petición
  params['page[number]'] = (Object.keys(paginationEvent).length !== 0)
    ? `${tableObj.pagination.current}`
    : '1'
  params['page[size]'] = `${tableObj.pagination.pageSize}`
}

export const setPaginationv2 = (paginationEvent = {}, tableObj, params = {}) => {
  // Se escribe los nuevos datos en el obj de paginacion de la tabla
  if (Object.keys(paginationEvent).length !== 0) {
    tableObj.pagination.current = paginationEvent.current
    tableObj.pagination.pageSize = paginationEvent.pageSize
  }
  // Se agregan datos de paginación a la petición
  params.page = (Object.keys(paginationEvent).length !== 0)
    ? `${tableObj.pagination.current}`
    : '1'
  params.pageSize = `${tableObj.pagination.pageSize}`
}

export const getPagination = (response) => {
  // response contiene los datos de paginacion
  // Defaul de objeto de paginacion
  let frontendPagination = {
    current: 1,
    pageSize: 0,
    from: 0,
    to: 0,
    total: 0,
    lastPage: 0,
  }
  // Verificacion de que el objeto contenga los campos necesarios
  if (Object.keys(response).length > 0 &&
    Object.hasOwnProperty.call(response, 'current-page') &&
    Object.hasOwnProperty.call(response, 'per-page') &&
    Object.hasOwnProperty.call(response, 'from') &&
    Object.hasOwnProperty.call(response, 'total') &&
    Object.hasOwnProperty.call(response, 'last-page') &&
    Object.hasOwnProperty.call(response, 'to')
  ) {
    // Se actualiza el objeto default por los valores correspondientes
    frontendPagination = {
      current: response['current-page'],
      pageSize: response['per-page'],
      from: response.from,
      to: response.to,
      total: response.total,
      lastPage: response['last-page'],
    }
  }
  return frontendPagination
}

export const getPaginationv2 = (response) => {
  // response contiene los datos de paginacion
  // Defaul de objeto de paginacion
  let frontendPagination = {
    current: 1,
    pageSize: 0,
    from: 0,
    to: 0,
    total: 0,
    lastPage: 0,
  }
  // Verificacion de que el objeto contenga los campos necesarios
  if (Object.keys(response).length > 0 &&
    Object.hasOwnProperty.call(response, 'current_page') &&
    Object.hasOwnProperty.call(response, 'per_page') &&
    Object.hasOwnProperty.call(response, 'from') &&
    Object.hasOwnProperty.call(response, 'total') &&
    Object.hasOwnProperty.call(response, 'last_page') &&
    Object.hasOwnProperty.call(response, 'to')
  ) {
    // Se actualiza el objeto default por los valores correspondientes
    frontendPagination = {
      current: response.current_page,
      pageSize: parseInt(response.per_page),
      from: response.from,
      to: response.to,
      total: response.total,
      lastPage: response.last_page,
    }
  }
  return frontendPagination
}
