import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const defaultSelectedWarehouse = {
  id: '',
  name: '',
}

export default {
  namespaced: true,
  state: {
    form: {
      warehouse_id: undefined,
      user_id: undefined,
      operation_shift_id: undefined,
      operation_area_id: undefined,
      transactionable_type: 'App\\Models\\User',
      transactionable_id: undefined,
      dated_at: '',
      reference: '',
      comments: '',
      auxWarehouse_id: undefined,
    }, // Transaction
    exitForm: {
      management_type_id: undefined,
      formulation_type_id: undefined,
      truck_id: undefined,
      semarnat: '',
      dated_at: '',
      final_destiny: '',
      social_reason: '',
    }, // exit_manifests
    smcCart: [], // sapMaterialContracts
    titemCart: [], // titems
    selectedPosition: '',
    selectedWarehouse: defaultSelectedWarehouse,
    // Locations
    visibleDrawer: false,
    wpositionForm: {
      resourceType: 'wpositions',
      form: {
        id: '',
        wlevels: '',
        column: '',
        row: '',
        name: '',
        status: '',
      },
      relationships: ['wlevels'],
    },
    detailWpositionDrawer: {},
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    MUTATE_SMC: (state, newItem) => {
      state.smcCart.push(newItem)
    },
    DELETE_SMC: (state, deleteItem) => {
      const i = state.smcCart.map(item => item.id).indexOf(deleteItem.id)
      state.smcCart.splice(i, 1)
    },
    MUTATE_TITEM: (state, newItem) => {
      state.titemCart.push(newItem)
    },
    DELETE_TITEM: (state, deleteItem) => {
      const i = state.titemCart.map(item => item.id).indexOf(deleteItem.id)
      state.titemCart.splice(i, 1)
    },
    SET_WPOSITION: (state, newItem) => {
      state.selectedPosition = newItem
    },
    SET_WAREHOUSE: (state, newItem) => {
      state.selectedWarehouse = newItem
    },
    SET_DRAWER: (state, value) => {
      state.visibleDrawer = value
    },
    SET_DETAILWPOSITIONDRAWER: (state, newItem) => {
      state.detailWpositionDrawer = newItem
    },
    SET_WPFORM: (state, form) => {
      state.wpositionForm.form = form
    },
  },
  actions: {
    // Entradas
    STORE_SMC({ commit }, newItem) {
      commit('MUTATE_SMC', newItem)
    },
    DELETE_SMC({ commit }, deleteItem) {
      commit('DELETE_SMC', deleteItem)
    },
    // Entradas
    // Salidas - Traspasos
    STORE_TITEM({ commit }, newItem) {
      commit('MUTATE_TITEM', newItem)
    },
    DELETE_TITEM({ commit }, deleteItem) {
      commit('DELETE_TITEM', deleteItem)
    },
    // Salidas - Traspasos
    SELECT_WPOSITION({ commit }, newItem) {
      commit('SET_WPOSITION', newItem)
    // Ubicacciones
    },
    SELECT_WAREHOUSE({ commit }, newItem) {
      commit('SET_WAREHOUSE', newItem)
    },
    TOOGLE_DRAWER({ commit }, value) {
      commit('SET_DRAWER', value)
    },
    STORE_DETAILWPOSITIONDRAWER({ commit }, wposition) {
      commit('SET_DETAILWPOSITIONDRAWER', wposition)
    },
    UPDATE_WPFORM({ commit }, payload) {
      commit('SET_WPFORM', payload)
    },
    RESET_WPFORM({ commit }) {
      commit('SET_STATE', {
        wpositionForm: {
          resourceType: 'wpositions',
          form: {
            id: '',
            wlevels: '',
            column: '',
            row: '',
            name: '',
            status: '',
          },
          relationships: ['wlevels'],
        }, // titems for locatio view
      })
    },
    // Ubicacciones
    // Formularios
    UPDATE_FORM({ commit }, payload) {
      commit('SET_STATE', {
        form: payload,
      })
    },
    UPDATE_EXIT_FORM({ commit }, payload) {
      console.log(payload)
      commit('SET_STATE', {
        exitForm: payload,
      })
    },
    // Formularios
    // Resets --Ubicacciones
    RESET_LOCATIONS({ commit }) {
      commit('SET_WAREHOUSE', defaultSelectedWarehouse)
      commit('SET_STATE', {
        titemCart: [], // titems for locatio view
      })
    },
    RESET_WAREHOUSE({ commit }) {
      commit('SET_WAREHOUSE', defaultSelectedWarehouse)
    },
    RESET_WPOSITION({ commit }) {
      commit('SET_WPOSITION', '')
    },
    // Resets --Transactions
    RESET_FORMS({ commit }) {
      commit('SET_STATE', {
        form: {
          warehouse_id: undefined,
          user_id: undefined,
          operation_shift_id: undefined,
          operation_area_id: undefined,
          transactionable_type: 'App\\Models\\User',
          transactionable_id: undefined,
          dated_at: '',
          reference: '',
          comments: '',
          auxWarehouse_id: undefined,
        },
        exitForm: {
          management_type_id: undefined,
          formulation_type_id: undefined,
          truck_id: undefined,
          semarnat: '',
          dated_at: '',
          final_destiny: '',
          social_reason: '',
        },
        smcCart: [], // sapMaterialContracts
        titemCart: [], // titems
      })
    },
    RESET_CARTS({ commit }) {
      commit('SET_STATE', {
        smcCart: [], // sapMaterialContracts
        titemCart: [], // titems
      })
    },
    CLEAN_STATE({ commit }) {
      commit('SET_STATE', {
        form: {},
        exitForm: {},
        smcCart: [], // sapMaterialContracts
        titemCart: [], // titems
        selectedPosition: '',
        selectedWarehouse: {},
      })
    },
  },
  getters: {
    smcCartItems: state => {
      return state.smcCart.length
    },
    titemCartItems: state => {
      return state.titemCart.length
    },
    titemCartIds: state => {
      const ids = []
      if (state.titemCart.length > 0) {
        state.titemCart.forEach(titem => {
          ids.push(titem.code)
        })
      }
      return ids
    },
  },
}
